import React, { useContext, useEffect, useState } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client';
import { Button, Drawer, TextField, Box, Typography, CardMedia, Card, Chip, Snackbar, Divider, Fab, CardContent, CardActions, Modal } from '@mui/material';
import { UserContext } from '../../../context/usercontext';
import Progress from '../../../components/mods/progress';
import { useItem } from '../../finance/mangeshop/useitems';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { useFormik } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useProdUpload from '../../profile/produpload';
import ClaimBeans from '../../mods/dialogue/claimbeans';
import { ArrowBack } from '@mui/icons-material'
//import AddItem from '../components/finance/mangeshop/additem'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import habisha from '../../../images/rlady.jpg';
import upload from '../../../images/upload.png';
import useFileUpload from '../cloud';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import coffeebeans from '../../../images/coffeebeans.png';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';





const BARISTA_BY_USERID = gql`
  query BaristaByUserID($userID: ObjectId!) {
    barista(query: { userID: $userID }) {
    
      _id
      shop
      craft
      plug
      store
      userID
    }
  }
`;

const CREATE_KUPSTER = gql`
  mutation CreateKupster($data: KupsterInsertInput!) {
    insertOneKupster(data: $data) {
      _id
      favorite_koffie
      Title
      userID
    }
  }
`;

const UPDATE_ONE_KUPSTER = gql`
  mutation UpdateUserprof($query: KupsterQueryInput!, $set: KupsterUpdateInput!) {
    updateOneKupster(query: $query, set: $set) {
      _id
      favorite_koffie
      Title
      userID
      
    }
  }
`;

const GET_BEAN_COUNT = gql`
  query GetBeanCount($_id: ObjectId!) {
    userprof(query: {_id: $_id}) {
      beanCount
      _id
    }
  }
`;

const DECREMENT_BEAN_COUNT = gql`
  mutation DecrementBeanCount($_id: ObjectId!, $beanCount_inc: Int!) {
    updateOneUserprof(query: { _id: $_id }, set: { beanCount_inc: $beanCount_inc }) {
      _id
      beanCount
    }
  }
`;

const MY_ADDS = gql`
  query MyAdds ($userID: String!) {
    aiadds (query: {userID: $userID}) {
    Title
		When
		_id
		description
		price
		primaryImage
		productID
		userID
    beanamount
  }
}
  
`; 

const UPDATE_ISADD = gql`
  mutation UpdateOneAiadd($query: AiaddQueryInput!, $set: AiaddUpdateInput!) {
    updateOneAiadd(query: $query, set: $set) {
      _id
      isAdd
      # Include other fields you might want to return
    }
  }
`;

const DELETE_ONE_AIADD = gql`
  mutation DeleteOneAiadd($query: AiaddQueryInput!) {
    deleteOneAiadd(query: $query) {
      _id
      
    }
  }
`;

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function Baristadash() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const {user} = useContext(UserContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerOpen1, setIsDrawerOpen1] = useState(false);
  const [message, setMessage] = useState('');
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [OpenModal, setOpenModal] = useState(false);
  const theme = useTheme();

  //open modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  //close modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleExpandClick = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const { data: aiAdds, loading: aiLoading, error: aiError } = useQuery(MY_ADDS,
    {variables: { userID: user ? user.id : null } });
    const [updateIsAdd] = useMutation(UPDATE_ISADD);

 const { Nickname } = user.customData;
  console.log("User from context:", Nickname);

  console.log("Querying with userID:", user.id);
  console.log("Querying with userID:", user.id);
  const { loading, error, data, refetch } = useQuery(BARISTA_BY_USERID, {
    variables: { userID: user ? user.id : null },
  });
   // Ensure that the user ID is available
  useEffect(() => {
    console.log("User from context:", user);
  }, [user]);

  const { file, previewURL, handleFileChange, uploadFile } = useFileUpload(); // Use your custom hook
    const [showDialog, setShowDialog] = useState(false);
    const [beanCount, setBeanCount] = useState(0); // Initialize with 0 beans
    const [postSubmitted, setPostSubmitted] = useState(false); // Added state for postSubmitted
    const fileInputRef = React.createRef();
    const { data: beanData, loading: beanLoading, error: beanError } = useQuery(GET_BEAN_COUNT, {
      variables: { _id: user.id },
    });
    const [decrementBeanCount] = useMutation(DECREMENT_BEAN_COUNT);
    const [updateKupster] = useMutation(UPDATE_ONE_KUPSTER);

     //Delete one Add
  const [deleteOneAiadd] = useMutation(DELETE_ONE_AIADD,
    { refetchQueries: [{ query: MY_ADDS, variables: { userID: user ? user.id : null } }] });
    
  // Add this line to handle errors
  const handleUpdateIsAdd = (_id) => {
    updateIsAdd({
      variables: {
        query: { _id: _id }, // Specify which document to update
        set: { isAdd: true } // Set isAdd to true
      }
    })
    if (beanCount < 1) {
      setShowDialog(true); // This will automatically show the dialog
      return;
    }
    decrementBeanCount({
      variables: { _id: user.id, beanCount_inc: -200 }
    })

    .then(response => {
      console.log('Update successful', response);
      handleOpenModal();
      // Perform any actions after successful update, e.g., refresh data or UI
    })
    .catch(error => {
      console.error('Error updating isAdd', error);
    });
  };

  const handleDeleteAdd = async (item) => {
    try {
      const { data } = await deleteOneAiadd({
        variables: {
          query: { _id: item._id },
        },
      });

      if (data.deleteOneAiadd) {
        setMessage(`Deleted: ${data.deleteOneAiadd.name}`);
        // Optionally, update local state or refetch queries here
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

    useEffect(() => {
        if (beanData && beanData.userprof) {
            setBeanCount(beanData.userprof.beanCount);
        }
    }, [beanData]);

  const [editProduct, setEditProduct] = useState(null);  // For storing product being edited
  const [openEditDialog, setOpenEditDialog] = useState(false);  // For controlling edit dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);  // For controlling delete dialog
  const { products, handleInsertProduct, handleUpdateProduct, handleDeleteProduct } = useItem();
  const [createBarista] = useMutation(CREATE_KUPSTER,
    {
      refetchQueries: [{ query: BARISTA_BY_USERID, variables: { userID: user ? user.id : null } }],
      variables: { _id: user.id },
    });

  const handleDrawerOpen1 = () => {
    setIsDrawerOpen(true);
  };
  
  const handleDrawerClose1 = () => {
    setIsDrawerOpen(false);
  };
  const query = { userID: user ? user.id : null }; // assuming you identify users by an _id

  const dataExists = data && data.kupster && data.kupster.userID; // Example condition
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
  
    // Prepare the input data
    const input = {
      flavor_profile: formData.get('Title'),
      favorite_koffie: formData.get('favorite_koffie')
     
    };
  
    // Check if the data exists (you need a way to determine this)
    if (dataExists) { // Implement your logic to determine if data exists
      // Update the profile
      updateKupster({ variables: { data } })
        .then(response => {
          console.log('Profile Updated:', response.data);
          handleDrawerClose();
        })
        .catch(error => {
          console.error('Could not update profile:', error);
        });
    } else {
      createBarista({ variables: { data } })
        .then(response => {
          console.log('New Profile Created:', response.data);
          handleDrawerClose();
        })
        .catch(error => {
          console.error('Could not create profile:', error);
        });
    }
  };
  
  // Drawer with Form
  const renderDrawer = () => (
    <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose1}>
      <Box
        sx={{ width: '100%', padding: 3, }}
        role="presentation"
        component="form"
        noValidate
        onSubmit={handleSubmit}
      >
        <IconButton onClick={handleDrawerClose1}>
          <ArrowBack />
        </IconButton>
        <Card sx={{ padding: 3, marginBottom: 2 }}>
        <Typography variant="h4" component="h2"fontWeight={500} color='#e39024'>
          Coming Soon!
          </Typography>
          </Card>
        <TextField label="Title" name="Title" fullWidth />
        <TextField label="Favorite Koffie" name="favorite_koffie" fullWidth />

        <Button type="submit">Save Settings</Button>
      </Box>
    </Drawer>
  );

  

  const startEditing = (product) => {
    setEditProduct(product);
    setOpenEditDialog(true);
  };

  const startDeleting = (product) => {
    setEditProduct(product);
    setOpenDeleteDialog(true);
  };

  const handleEdit = () => {
    // Call your update function here, e.g.
    handleUpdateProduct(editProduct);
    setOpenEditDialog(false);
  };

  const handleDelete = () => {
    // Call your delete function here, e.g.
    handleDeleteProduct(editProduct._id);
    setOpenDeleteDialog(false);
  };


 
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  

 // Function to determine cols and rows based on some condition, you can adjust this logic
 const getColsAndRows = (product) => {
  // You could replace this with your actual logic to determine featured products
  return product.featured ? { cols: 2, rows: 2 } : { cols: 1, rows: 1 };
};

const handleOpenDialog = () => {
  setOpenDialog(true);
};

const handleCloseDialog = () => {
  setOpenDialog(false);
  setOpenSnackbar(true);
};

const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpenSnackbar(false);
};

 const formik = useFormik({
  initialValues: {
    productName: '',
    productDescription: '',
    productPrice: '',
    // productImage: '', // This can be removed if you're not using it
  },
  onSubmit: async (values, { resetForm }) => {
    // Directly call uploadFile function to upload the image
    
    const uploadedImageURL = await uploadFile(file, user.id);

    // Check if the upload was successful
    if (!uploadedImageURL) {
      console.error('File upload failed: no image URL returned');
      handleCloseDialog();
      return;
    }
    // Create the new product item with the uploaded image URL
    const newProduct = {
      name: values.productName,
      description1: values.productDescription,
      price: values.productPrice,
      imageurl: uploadedImageURL, // Use the uploaded image URL here
      userID: user.id, // Make sure this is the correct way to access the user's ID
    };
      
    // Insert the product item
    handleInsertProduct(newProduct);

    // Reset the form and close any modals/drawers
    resetForm(); // You can use the formik bag's resetForm function
    handleCloseDialog();
  },
  // ... You can add validationSchema or validate function if needed
});


  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  
  useEffect(() => {
    if (user && user.user && user.user.id) {
      // Trigger refetch or some other action
    }
  }, [user]);
  
  
    
  console.log("Data from query:", data);
  const handleFabClick = () => {
    fileInputRef.current.click();
};



  // Add this line to handle errors
  if (error) return <div>Error: {error.message}</div>;

  if (loading) return <div><Progress/></div>;
  
  return (
    <div>
   
      {data && data.barista ? (
        Array.isArray(data.barista) ? (
          data.barista.map((item, index) => (
            <div key={index}>
              {item.plug ? item.plug : "No Favorite"}
              {item.store? item.store: "No Title"}
            </div>
          ))
        ) : (
          <Card style={{ textAlign: 'center' }}>
            {data.barista.plug ? data.barista.plug : "No Favorite"}
            {data.barista.store ? data.barista.store : "No Title"} 
          </Card>
        )
      ) : (
        <Card style={{ textAlign: 'center' }}>
          <Typography variant="h6" gutterBottom>
            Options
          </Typography>
          <Typography variant="subtitle1">
            Set up your custom settings for a better experience
          </Typography>
          <Chip 
  label="Customize Settings"  
  style={{ margin: '10px 0' }} 
  onClick={handleDrawerOpen1} 
/>
        </Card>
      )}
    
    <Box sx={{ width: '100%', paddingTop: 2, paddingBottom: 2 }}>
    <Card sx={{ 
  height: '10vh', 
  width: '100%',
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'space-between', 
  padding: '0 10px',
  
}}>
  <Typography variant="h6">
    Manage Shop
  </Typography>
  
  <Chip
    label="Manage Profile"
    onClick={handleDrawerOpen}
    backgroundColor="#2fa849"
    style={{ cursor: 'pointer', backgroundColor: '#2fa849', color: '#fff' }}
  />
</Card>
{renderDrawer()}
</Box>

 

    
      
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        sx={{ width: "100%" }}
      >
        <Box sx={{ width: '100%', padding: 0 }}>
        <Box display="flex" alignItems="center">
      <IconButton onClick={handleDrawerClose}>
        <ArrowBack />
      </IconButton>
     
    </Box>
          
         
          <Box sx={{ padding: 5, }}>
          <Typography variant="h5" sx={{fontWeight: 500, margin: 3}} >
              {Nickname}'s Collection 
            
            </Typography>
          <Card sx={{ padding: 3, marginBottom: 2 }}>
        {/* Display your shopping cart item details here */}
        <Button variant="contained" onClick={handleOpenDialog}>
          Add Item
        </Button>
      </Card>
          
          <Dialog open={openDialog} onClose={handleCloseDialog}>
          <form onSubmit={formik.handleSubmit}>
          <Typography variant="h6" sx={{ marginBottom: 2 }}> {/* Adjust spacing as needed */}
            Add Artisanal Item
          </Typography>
           {/* Image preview */}
      {previewURL && (
        <CardMedia className="fade-in"component="img" alt="Preview" image={previewURL} sx={{ width: '100%', height: 'auto', borderRadius: 5}} />
      )}
      {/* File input */}
  
      <input 
        type="file" 
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
       
          <div style={{ display: 'flex', alignItems: 'center' }}>
      <div variant="contained"  onClick={handleFabClick}>
        <img src={upload} alt='up' width={50} />
      </div>
      <Typography variant="caption" sx={{ marginLeft: 5 }}>
        Select Image
      </Typography>
    </div>
            <TextField
              label="Product Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="productName"
              onChange={formik.handleChange}
              value={formik.values.productName}
            />
           
           
            
            <TextField
              label="Product Description"
              variant="outlined"
              fullWidth
              margin="normal"
              name="productDescription"
              onChange={formik.handleChange}
              value={formik.values.productDescription}
            />
            <TextField
              label="Product Price"
              variant="outlined"
              fullWidth
              margin="normal"
              name="productPrice"
              onChange={formik.handleChange}
              value={formik.values.productPrice}
            />
            <Fab
              variant="extended"
              color="primary"
              type="submit"
              sx={{ marginTop: 2, marginRight: 2 }}
            >
              Add Product
            </Fab>
             
            
          </form>
          </Dialog>
          <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Item added successfully"
      />
          </Box>
        </Box>
        
        <div>
         
        
        <Divider variant='middle'  />
      {/* Edit Product Dialog */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent>
          {/* Place your form fields for editing here, e.g., */}
          <TextField
            label="Product Name"
            fullWidth
            value={editProduct?.name || ''}
            onChange={(e) => setEditProduct({ ...editProduct, name: e.target.value })}
          />
           <TextField
            label="Product Price"
            fullWidth
            value={editProduct?.price || ''}
            onChange={(e) => setEditProduct({ ...editProduct, price: e.target.value })}
          />
          <TextField
            label="Product Description"
            fullWidth
            value={editProduct?.description1 || ''}
            onChange={(e) => setEditProduct({ ...editProduct, description1: e.target.value })}
          />
          <TextField
            label="Image URL"
            fullWidth
            value={editProduct?.imageurl || ''}
            onChange={(e) => setEditProduct({ ...editProduct, imageurl: e.target.value })}
          />
          {/* Add more fields as needed */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)}>Cancel</Button>
          <Button onClick={handleEdit}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Product Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this product?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>

      <List>
        
        {products.map((product, index) => (
          <Card sx={{ padding: 0, marginBottom: 2 }}>
          <ListItem key={index}>
            <ListItemAvatar>
          <Avatar variant="rounded">
            <img src={product.imageurl} alt={product.name} width={40}/>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={product.name}
          secondary={product.description1}
        />
            {/* ... (Your existing ListItem code) */}
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={() => startEditing(product)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => startDeleting(product)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          
          </ListItem>
          </Card>
        ))}
       
      </List>
      
    </div>
      </Drawer>
      <ImageList
  sx={{
   
    overflowY: 'auto', // Allows scrolling within the ImageList if needed
    boxShadow: 0,
    borderRadius: 5,
    padding: 2,
    bgcolor: '#transparent',
  }}
  rowHeight={100}
  gap={1}
  cols={4}
  
>
  

      {products.map((product) => {
        const { cols, rows } = getColsAndRows(product);

        return (
          <Link to={`/product/${product._id}`} key={product._id}>
          <ImageListItem variant key={product.id} cols={cols} rows={rows}
          sx={{}}
          >
            <img
              src={product.imageurl}
              alt={product.name}
              loading="lazy"
              style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: 15, padding: 5 }}
            />
            <ImageListItemBar
            sx={{background: 'none'}}
              title={product.name}
              subtitle={'$' + product.price}
              position="bottom"
             
            />

          </ImageListItem>
          </Link>
        );
      })}
    </ImageList>
    
      <Card sx={{
        height: 'auto', // Changed from '10vh' to 'auto' to accommodate the list
        width: '100%',
        display: 'flex',
        flexDirection: 'column', // Changed to 'column' to stack items vertically
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 5,
       
        
      }}>
         <CardMedia sx={{ textAlign: 'center', width: '100%', padding: 0, mt: 2, mb: 2, bgcolor: 'transparent',  }}>
            <Typography variant="h6" gutterBottom sx={{fontWeight:700, textAlign: 'left', }}>
            Add generated add to live feed/market
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ textAlign: 'left', }}>
            The AI smart cart has generated some smart sales for you. Add them to the live feed to generate user interest.
            </Typography>
          </CardMedia>
          {aiAdds && aiAdds.aiadds.length > 0 ? (
  Array.isArray(aiAdds.aiadds) ? (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 , padding: 1}}>
      {aiAdds.aiadds.map((item, index) => (
        <Card key={index} sx={{ width: '100%', flexDirection: 'column', boxShadow: 5, borderRadius: 5,  padding: 1, border: 'solid 1px'}}>
          <CardMedia
            component="img"
            height="150"
            image={item.primaryImage}
            alt={item.Title ? item.Title : "No Title"}
            style={{ objectFit: 'cover', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}
          />
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {item.Title ? item.Title : "No Title"}
            </Typography>
            <Typography variant="body1" color='textSecondary'
            sx={{ 
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 1, /* number of lines to show */
              WebkitBoxOrient: 'vertical'
            }}
            >
              {item.description ? item.description : "No Description"}
            </Typography>
          </CardContent>
          <CardActions>
            <Chip label="Add to Market" onClick={() => handleUpdateIsAdd(item._id)} />
            <Chip label="Remove" onClick={() => handleDeleteAdd(item)} />
            <Box sx={{ flexGrow: 1 }} />
            <Chip
              label="500"
              avatar={<Avatar src={coffeebeans} alt="Bean" />}
              variant="outlined"
            />

            <IconButton
              onClick={() => handleExpandClick(index)}
              aria-expanded={expandedIndex === index}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={expandedIndex === index} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph>
                {item.description ? item.description : "No Description"}
              </Typography>
            </CardContent>
          </Collapse>
        </Card>
      ))}
    </div>
  ) : (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Card sx={{ maxWidth: 400 }}>
        <CardContent>
          <Typography variant="body1">
            {aiAdds.aiadds.description ? aiAdds.aiadds.description : "No Description"}
          </Typography>
        </CardContent>
        <CardActions>
          <Typography variant="body1">
            {aiAdds.aiadds.price ? aiAdds.aiadds.price: "No Price"}
          </Typography>
        </CardActions>
      </Card>
    </div>
  )
) : (
  <Card sx={{ textAlign: 'center', width: '100%', padding: '20px', marginTop: '10px' }}>
    <Typography variant="h6" gutterBottom>
      No Smart Sales Generated Yet
    </Typography>
    <Typography variant="body2" color="green">
      <Link to="/post" style={{ textDecoration: 'none', color: 'inherit' }}>
        Post and share to generate user interest
      </Link>
    </Typography>
  </Card>
)}


      </Card>
      <Modal open={OpenModal} onClose={handleCloseModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',
   
  }}>
   <Box sx={{ width: 400, padding: 3,  borderRadius: 5, backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff', border: 'solid 1px #d1d1d1' }}>
   <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 0 }}>
   <Typography variant="h6" sx={{ fontWeight: 600, textAlign: 'left' }}>
     MarketPlace
   </Typography>
   <IconButton onClick={handleCloseModal}>
     <CloseIcon />
   </IconButton>
 </Box>
 
     <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0 }}>
       <img src='https://storage.googleapis.com/app_darkendimg/lilbeanie.png' alt='newton' width={80} />
     
     </Box>
     <Typography variant="body1" sx={{ textAlign: 'center', margin: 2,  }}>
       Your Item has been added to the market. Users can now view and purchase your product from the live feed.
     </Typography>
    
   </Box>
 </Modal>   

  </div>
  )
}

export default Baristadash;
import React from 'react'
import { Box, Grid, ButtonBase, Typography, CardMedia, Icon, Button, useMediaQuery } from '@mui/material'
import { Link } from 'react-router-dom'
import { IconButton } from '@mui/material'
import FileUploadComponent from '../components/profile/fileupload'
import { Divider } from '@mui/material'
import LogoutButton from '../components/profile/logout'
import Display from '../components/profile/settings/displaymode'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'


function Middle() {
const theme = useTheme()
const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const navigate = useNavigate();
   
  
  const handleClose = () => {
        console.log('closing')
    }

    const goToFaqs = () => {
  navigate('/faq')
}


const goToDigi = () => {
  navigate('/coffeeshop/65142ac4953f2dbf35f0fdd5')
}
  return (
    <Box sx={{ flex: 'grow', borderRadius: 20 , mt: 5, height: '100vh' }}>
      <>
     {isMobile ? 
     <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 1, borderRadius: 20 }}>
       <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 1, borderRadius: 20 }}>
        <Typography variant='h5' sx={{ fontWeight: 800, color: 'text.secondary' }}>
          Smart Koffie
        </Typography>
        <Typography variant='body2' sx={{ color: '#077336', fontWeight: 700 }}>
          KaaF - Koffie as a Function 
        </Typography>
      </Box>
            <Grid container spacing={4}sx={{ alignContent: 'center', alignItems: 'center', justifyContent: 'center', padding: 1, borderRadius: 20}}>
            

<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
  <Link onClick={handleClose} to="/dashboard" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <IconButton sx={{ color: 'text.secondary' }}>
        <img src='https://storage.googleapis.com/app_darkendimg/assets/thebag.png' alt='Koffie' style={{ height: 50 }} />
      </IconButton>
    
    </Box>
    <Typography variant='body2'sx={{fontWeight: 600, color: 'text.secondary'}}>
    Bean Bag
    </Typography>
  </Link>
              </Grid>
    
<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
  <Link onClick={handleClose} to="/main" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <IconButton sx={{ color: 'text.secondary' }}>
        <img src='https://storage.googleapis.com/app_darkendimg/assets/kit.png' alt='Koffie' style={{ height: 50 }} />
      </IconButton>
    
    
    </Box>
    <Typography variant='body2' sx={{fontWeight: 600, color: 'text.secondary'}}>
    Koffie Kit
    </Typography>
  </Link>
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
  <Link onClick={handleClose} to="/allkoffie" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',  }}>
    <IconButton sx={{ color: 'text.secondary' }}>
        <img src='https://storage.googleapis.com/app_darkendimg/assets/statekoffie.png' alt='Koffie' style={{ height: 50 }} />
      </IconButton>
    
    </Box>
   <Typography variant='body2'sx={{fontWeight: 600, color: 'text.secondary'}}>
    States
    </Typography>
  </Link>
</Grid>
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
  <Link onClick={handleClose} to="/chron" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <IconButton sx={{ color: 'text.secondary' }}>
        <img src='https://storage.googleapis.com/app_darkendimg/assets/koffiemap.png' alt='Koffie' style={{ height: 50 }} />
      </IconButton>
   
    </Box>
    <Typography variant='body2' sx={{fontWeight: 600, color: 'text.secondary'}}>
    Journey
    </Typography>
  </Link>
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
  <Link onClick={handleClose} to="/explore" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <IconButton sx={{ color: 'text.secondary' }}>
        <img src='https://storage.googleapis.com/app_darkendimg/assets/greenhand.webp' alt='Koffie' style={{ height: 50 }} />
      </IconButton>
    
    </Box>
    <Typography variant='body2' sx={{fontWeight: 600, color: 'text.secondary'}}>
    Koffielanders
    </Typography>
  </Link>
              </Grid>

             

             
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
  <Link onClick={handleClose} to='/user-market' style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <IconButton sx={{ color: 'text.secondary' }}>
        <img src='https://storage.googleapis.com/app_darkendimg/assets/marketgreen.png' alt='Koffie' style={{ height: 50 }} />
      </IconButton>
   
    </Box>
    <Typography variant='body2' sx={{fontWeight: 600, color: 'text.secondary'}}>
    Market Place
    </Typography>
  </Link>
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
  <Link onClick={handleClose} to="/reviews" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <IconButton sx={{ color: 'text.secondary' }}>
        <img src='https://storage.googleapis.com/app_darkendimg/products/takeout.png' alt='Koffie' style={{ height: 50 }} />
      </IconButton>

   
    </Box>
    <Typography variant='body2'sx={{fontWeight: 600, color: 'text.secondary'}}>
    Rewards
    </Typography>
  </Link>
              </Grid>
             
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
  <Link onClick={handleClose} to="/goals" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <IconButton sx={{ color: 'text.secondary' }}>
        <img src='https://storage.googleapis.com/app_darkendimg/products/doit.png' alt='Koffie' style={{ height: 50 }} />
      </IconButton>
    </Box>
    
    <Typography variant='body2' sx={{fontWeight: 600, color: 'text.secondary'}}>
    DoIT
    </Typography>
  </Link>
              </Grid>
            
              
              
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
  <Link onClick={handleClose} to="/contractdetails" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <IconButton sx={{ color: 'text.secondary' }}>
        <img src='https://storage.googleapis.com/app_darkendimg/products/smkontract.png' alt='Koffie' style={{ height: 50 }} />
      </IconButton>

    
    </Box>
    <Typography variant='body2' sx={{fontWeight: 600, color: 'text.secondary'}}>
    Contracts
    </Typography>
  </Link>
              </Grid>
             
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
  <Link onClick={handleClose} to="/bean-report" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <IconButton sx={{ color: 'text.secondary' }}>
        <img src='https://storage.googleapis.com/app_darkendimg/assets/beanreport.png' alt='Koffie' style={{ height: 40 }} />
      </IconButton>

    
    </Box>
    <Typography variant='body2'sx={{fontWeight: 600, color: 'text.secondary'}}>
    Reports
    </Typography>
  </Link>
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
  <Link onClick={handleClose} to="/beanlist" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <IconButton sx={{ color: 'text.secondary' }}>
        <img src='https://storage.googleapis.com/app_darkendimg/assets/kcoin.png' alt='Koffie' style={{ height: 50 }} />
      </IconButton>
    </Box>
    
    <Typography variant='body2' sx={{fontWeight: 600, color: 'text.secondary'}}>
    Beanomics
    </Typography>
  </Link>
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
    <Link onClick={handleClose} to="/feedback" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Button  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 5, backgroundColor: '#077336', color: '#fff', border: 'none' }}>
        
        FEEDBACK
      
    </Button>
               </Link>
               
                </Grid>
                
                  <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Display />
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <LogoutButton />
                  </Grid>
                  <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button onClick={goToFaqs} sx={{ borderRadius: 5, backgroundColor: '#3ff107', color: '#000', border: 'none', ":hover": { backgroundColor: '#ffc107' }}}
                >
                  FAQ
                </Button>
                </Grid>
            </Grid>
            
              </Box> 
     : null}

      </>

      <>
     {!isMobile ? 
     <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 1, borderRadius: 20 }}>
       <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 1, borderRadius: 20 }}>
        <Typography variant='h5' sx={{ fontWeight: 800, color: 'text.secondary' }}>
          Smart Koffie
        </Typography>
        <Typography variant='body2' sx={{ color: '#077336', fontWeight: 700 }}>
          KaaF - Koffie as a Function 
        </Typography>
      </Box>
            <Grid container spacing={4}sx={{ alignContent: 'center', alignItems: 'center', justifyContent: 'center', padding: 1, borderRadius: 20}}>
            

<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
  <Link onClick={handleClose} to="/dashboard" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <IconButton sx={{ color: 'text.secondary' }}>
        <img src='https://storage.googleapis.com/app_darkendimg/assets/thebag.png' alt='Koffie' style={{ height: 50 }} />
      </IconButton>
    
    </Box>
    <Typography variant='body2'sx={{fontWeight: 600, color: 'text.secondary'}}>
    Bean Bag
    </Typography>
  </Link>
              </Grid>
    

              
                       

              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
  <Link onClick={handleClose} to="/reviews" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <IconButton sx={{ color: 'text.secondary' }}>
        <img src='https://storage.googleapis.com/app_darkendimg/products/takeout.png' alt='Koffie' style={{ height: 50 }} />
      </IconButton>

   
    </Box>
    <Typography variant='body2'sx={{fontWeight: 600, color: 'text.secondary'}}>
    Rewards
    </Typography>
  </Link>
              </Grid>
             
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
  <Link onClick={handleClose} to="/goals" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <IconButton sx={{ color: 'text.secondary' }}>
        <img src='https://storage.googleapis.com/app_darkendimg/products/doit.png' alt='Koffie' style={{ height: 50 }} />
      </IconButton>
    </Box>
    
    <Typography variant='body2' sx={{fontWeight: 600, color: 'text.secondary'}}>
    DoIT
    </Typography>
  </Link>
              </Grid>
             
              
              
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
  <Link onClick={handleClose} to="/contractdetails" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <IconButton sx={{ color: 'text.secondary' }}>
        <img src='https://storage.googleapis.com/app_darkendimg/assets/kcoin.png' alt='Koffie' style={{ height: 50 }} />
      </IconButton>

    
    </Box>
    <Typography variant='body2' sx={{fontWeight: 600, color: 'text.secondary'}}>
    Contracts
    </Typography>
  </Link>
              </Grid>
             
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
  <Link onClick={handleClose} to="/bean-report" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <IconButton sx={{ color: 'text.secondary' }}>
        <img src='https://storage.googleapis.com/app_darkendimg/assets/beanreport.png' alt='Koffie' style={{ height: 40 }} />
      </IconButton>

    
    </Box>
    <Typography variant='body2'sx={{fontWeight: 600, color: 'text.secondary'}}>
    Reports
    </Typography>
  </Link>
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
  <Link onClick={handleClose} to="/beanlist" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <IconButton sx={{ color: 'text.secondary' }}>
        <img src='https://storage.googleapis.com/app_darkendimg/assets/brkoin ' alt='Koffie' style={{ height: 50 }} />
      </IconButton>
    </Box>
    
    <Typography variant='body2' sx={{fontWeight: 600, color: 'text.secondary'}}>
    Beanomics
    </Typography>
  </Link>
              </Grid>
                
                  <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Display />
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <LogoutButton />
                  </Grid>
                  <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button onClick={goToFaqs} sx={{ borderRadius: 5, backgroundColor: '#3ff107', color: '#000', border: 'none', ":hover": { backgroundColor: '#ffc107' }}}
                >
                  FAQ
                </Button>
                
                </Grid>
            </Grid>
          
              </Box> 
     : null}
        
      </>
      
              </Box> 
  )
}

export default Middle
import React, { useEffect, useRef } from 'react';
import { Box, CardMedia } from '@mui/material';

const MediaItem = ({ item, title }) => {
  const isVideo = /\.(mp4|webm|mov)$/i.test(item);
  const videoRef = useRef(null); // Ref for the video element

  // Manage video play/pause behavior based on visibility
  useEffect(() => {
    let observer;
    if (isVideo && videoRef.current) {
      observer = new IntersectionObserver(
        ([entry]) => {
          if (videoRef.current) { // Check if the video element is still mounted
            if (entry.isIntersecting) {
              videoRef.current.play(); // Play the video if it's visible
            } else {
              videoRef.current.pause(); // Pause the video if it's not visible
            }
          }
        },
        {
          threshold: 0.5, // Trigger when 50% of the video is visible
        }
      );

      observer.observe(videoRef.current); // Start observing the video
    }

    // Cleanup when the component unmounts or ref is invalid
    return () => {
      if (observer && videoRef.current) {
        observer.unobserve(videoRef.current); // Ensure valid unobserve
      }
    };
  }, [isVideo]);

  return (
    <Box sx={{ marginBottom: '10px', width: '100%' }}>
      {isVideo ? (
        <video
          ref={videoRef}
          muted
          controls
          style={{ borderRadius: 10, width: '100%', padding: 0 }}
          playsInline // Prevents iOS from forcing fullscreen
        >
          <source src={item} type="video/mp4" />
          Sorry, your browser doesn't support embedded videos.
        </video>
      ) : (
        <CardMedia
          component="img"
          image={item}
          alt={`${title}`}
          sx={{
            borderRadius: 2,
            padding: 0,
            objectFit: 'contain',
            width: '100%',
            height: 'auto',
          }}
        />
      )}
    </Box>
  );
};

export default MediaItem;

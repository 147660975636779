import React, { useState, useEffect } from 'react'
import Web3 from 'web3'
import Artifacts from './contracts/KupToken.json'
import { CoffeeTokenAddress } from './constants'
import { Paper, Grid, Stack, Box, Card, Drawer, IconButton, Typography, Button , List, CardContent, ListItemAvatar,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  LinearProgress,
  Avatar, useMediaQuery, Modal, Icon
   } from '@mui/material'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import beans from '../images/coffeebeans.png'
import fert from '../images/fert.webp'
import bud from '../images/bud.jpg'
import flower from '../images/flower.jpg'
import grow from '../images/grow.png'
import prune from '../images/prune.jpg'
import harvest from '../images/harvest.png'
import dry from '../images/dry.jpg'
import cdry from '../images/cdry.jpg'
import ripe from '../images/ripe.png'
import Divider from '@mui/material/Divider';
import InfoIcon from '@mui/icons-material/Info';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '10px solid #addeb8',
  borderRadius: 8,
  boxShadow: 24,
  p: 4,
};



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  
}));

const contractABI = Artifacts.abi;
const contractAddress = CoffeeTokenAddress.Contract[51];

// Initialize Web3 with Infura provider
const web3 = new Web3(new Web3.providers.HttpProvider("https://erpc.apothem.network"));

// Define the contract
const contract = new web3.eth.Contract(contractABI, contractAddress);

function Process() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [processStates, setProcessStates] = useState({
    fertilizing: null,
    flowering: null,
    drying: null,
    pruning: null,
    growing: null,
    budding: null,
    harvesting: null,
    pulping: null,
    fermenting: null,
    ripening: null,
  });

  // Fetch data
  const fetchContractData = async () => {
    const newProcessStates = {
      fertilizing: await contract.methods.fertilizing().call(),
      flowering: await contract.methods.flowering().call(),
      drying: await contract.methods.drying().call(),
      pruning: await contract.methods.pruning().call(),
      growing: await contract.methods.growing().call(),
      budding: await contract.methods.budding().call(),
      harvesting: await contract.methods.harvesting().call(),
      pulping: await contract.methods.pulping().call(),
      ripening: await contract.methods.ripening().call(),
    };

    setProcessStates(newProcessStates);
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
const [selectedItem, setSelectedItem] = useState(null);

// Function to handle opening the drawer
const handleDrawerOpen = (item) => {
  setSelectedItem(item);
  setDrawerOpen(true);
};

// Function to handle closing the drawer
const handleDrawerClose = () => {
  setDrawerOpen(false);
};
  const processItems = [
    { label: 'Fertilizing', value: processStates.fertilizing, imgSrc: fert, btnText: 'More Info', image1: 'https://storage.googleapis.com/app_darkendimg/assets/growing.jpg' , image2: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', image3: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', about: 'Fertilizing is the process of adding nutrients to the soil to help the coffee plants grow. This process is essential to the health and growth of the coffee plants. The nutrients are added to the soil to help the plants grow and produce the best coffee beans.'},
    { label: 'Growing', value: processStates.growing, imgSrc: grow, btnText: 'More Info' , image1: 'https://storage.googleapis.com/app_darkendimg/assets/treename.JPG' , image2: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', image3: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', about: 'Fertilizing is the process of adding nutrients to the soil to help the coffee plants grow. This process is essential to the health and growth of the coffee plants. The nutrients are added to the soil to help the plants grow and produce the best coffee beans.'},
    { label: 'Pruning', value: processStates.pruning, imgSrc: prune, btnText: 'More Info', image1: 'https://storage.googleapis.com/app_darkendimg/assets/farmers.jpg' , image2: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', image3: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', about: 'Fertilizing is the process of adding nutrients to the soil to help the coffee plants grow. This process is essential to the health and growth of the coffee plants. The nutrients are added to the soil to help the plants grow and produce the best coffee beans.' },
    { label: 'Flowering', value: processStates.flowering, imgSrc: flower, btnText: 'More Info',  image1: 'https://storage.googleapis.com/app_darkendimg/profilepics/bloom.jpg' , image2: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', image3: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', about: 'Flowering is the process of adding nutrients to the soil to help the coffee plants grow. This process is essential to the health and growth of the coffee plants. The nutrients are added to the soil to help the plants grow and produce the best coffee beans.' },
    { label: 'Budding', value: processStates.budding, imgSrc: bud, btnText: 'More Info', image1: 'https://storage.googleapis.com/app_darkendimg/assets/budding.jpeg' , image2: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', image3: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', about: 'Fertilizing is the process of adding nutrients to the soil to help the coffee plants grow. This process is essential to the health and growth of the coffee plants. The nutrients are added to the soil to help the plants grow and produce the best coffee beans.' },
    { label: 'Ripening', value: processStates.ripening, imgSrc: ripe, btnText: 'More Info' , image1: 'https://storage.googleapis.com/app_darkendimg/assets/ripening.jpg' , image2: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', image3: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', about: 'Fertilizing is the process of adding nutrients to the soil to help the coffee plants grow. This process is essential to the health and growth of the coffee plants. The nutrients are added to the soil to help the plants grow and produce the best coffee beans.'},
       { label: 'Harvesting', value: processStates.harvesting, imgSrc: harvest, btnText: 'More Info' , image1: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg' , image2: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', image3: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', about: 'Fertilizing is the process of adding nutrients to the soil to help the coffee plants grow. This process is essential to the health and growth of the coffee plants. The nutrients are added to the soil to help the plants grow and produce the best coffee beans.'},
    { label: 'Pulping', value: processStates.pulping, imgSrc: dry, btnText: 'More Info', image1: 'https://storage.googleapis.com/app_darkendimg/products/dadskoffie.jpg' , image2: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', image3: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', about: 'Fertilizing is the process of adding nutrients to the soil to help the coffee plants grow. This process is essential to the health and growth of the coffee plants. The nutrients are added to the soil to help the plants grow and produce the best coffee beans.' },
     { label: 'Fermenting', value: processStates.fermenting, imgSrc: beans, btnText: 'More Info' , image1: 'https://storage.googleapis.com/app_darkendimg/products/dadskoffie.jpg' , image2: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', image3: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', about: 'Fertilizing is the process of adding nutrients to the soil to help the coffee plants grow. This process is essential to the health and growth of the coffee plants. The nutrients are added to the soil to help the plants grow and produce the best coffee beans.'},
      { label: 'Drying', value: processStates.drying, imgSrc: cdry, btnText: 'More Info', image1: 'https://storage.googleapis.com/app_darkendimg/profilepics/hull.jpg' , image2: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', image3: 'https://storage.googleapis.com/app_darkendimg/rlady.jpg', about: 'Fertilizing is the process of adding nutrients to the soil to help the coffee plants grow. This process is essential to the health and growth of the coffee plants. The nutrients are added to the soil to help the plants grow and produce the best coffee beans.' },
    
];
  // Call the function when the component mounts
  useEffect(() => {
    fetchContractData();
  }, []);

  return (
    <>
    {isMobile ? (
     <div style={{
      overflowX: 'auto',
      display: 'flex',
      flexDirection: 'column',
      
      padding: 5,
      scrollbarWidth: 'none', /* For Firefox */
      msOverflowStyle: 'none', /* For Internet Explorer and Edge */
      scrollbarColor: 'none' /* Not needed if scrollbarWidth is used, but left here for reference */
    }}>
      <List>
        {processItems.map((item, index) => (
          <ListItem key={index} sx={{ width: '100%', marginBottom: 2, alignItems: 'flex-start' }}>
            <ListItemAvatar>
              <Avatar src={item.imgSrc} alt={item.label.toLowerCase()} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  {item.label}
                </Typography>
              }
              secondary={
                <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                  <LinearProgress
                    variant="determinate"
                    value={item.value === null ? 0 : item.value ? 100 : 0}
                    sx={{
                      height: 10,
                      borderRadius: 10,
                      flexGrow: 1,
                      marginRight: 2,
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: item.value === null ? 'grey' : item.value ? 'green' : '#000',
                      },
                    }}
                  />
                  <Typography variant="body2" color="textSecondary">
                    {item.value === null ? 'Loading...' : item.value ? <CheckCircleIcon color='success' /> : <ScheduleOutlinedIcon color='warning' />}
                  </Typography>
                </div>
              }
            />
            <IconButton onClick={() => handleDrawerOpen(item)}>
              <InfoIcon sx={{ color: 'black' }} />
            </IconButton>
          </ListItem>
        ))}
      </List>
    
    <Drawer
      anchor="bottom"
      open={drawerOpen}
      onClose={handleDrawerClose}
      sx={{
        '& .MuiDrawer-paper': {
          borderRadius: '20px 20px 0 0', // Top left, top right, bottom right, bottom left
          display: 'flex',
         
          alignItems: 'center',
          padding: 0,
        },
      }}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}
        role="presentation"
      >

<div style={{ position: 'relative', width: '100%' }}>
      <img src={selectedItem?.image1} alt={selectedItem?.label.toLowerCase()} style={{ width: '100%', height: 'auto', borderRadius: 10 }} />
      <div style={{
        position: 'absolute',
        top: 0, // Position at the top
        left: 0, // Position to the left
        backgroundColor: 'rgba(255, 255, 255, 0.5)', // Optional: add background color for better readability
        padding: '8px', // Add some padding around the text
      }}>
                <Typography variant="h6" fontWeight={900}>
          {selectedItem?.label}
        </Typography>
       
        <IconButton onClick={handleDrawerClose}>
          <CloseIcon
            sx={{
              color: 'black',
              fontSize: 20,
            }}
          />
        </IconButton>
      </div>
      
        
      {/* Additional content for the drawer, possibly using the 'selectedItem' state */}
    </div>
        <Divider />
        <List sx={{ width: '100%', maxWidth: 380, bgcolor: 'background.paper' }}>
          <ListItem>
            <ListItemIcon>
              <CheckCircleIcon />
            </ListItemIcon>
            <ListItemText primary={selectedItem?.label} secondary={selectedItem?.value ? 'Completed' : 'Not Completed'} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="fertilizing">
               <img src={selectedItem?.imgSrc} alt={selectedItem?.label.toLowerCase()} style={{ width: '50px', height: '50px' }} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
         
          </List>
          <Divider />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2}}>
           
            <Typography variant="body1" sx={{ p: 2 }}>
              {selectedItem?.about}
            </Typography>
          </Box>
        {/* ... more detailed content based on the selected item ... */}
       
      </Box>
    </Drawer>
  </div>
    ) : (
      <div style={{
        overflowX: 'auto',
        display: 'flex',
        flexDirection: 'column',
        
        padding: 5,
        scrollbarWidth: 'none', /* For Firefox */
        msOverflowStyle: 'none', /* For Internet Explorer and Edge */
        scrollbarColor: 'none' /* Not needed if scrollbarWidth is used, but left here for reference */
      }}>
        <List>
          {processItems.map((item, index) => (
            <ListItem key={index} sx={{ width: '100%', marginBottom: 2, alignItems: 'flex-start' }}>
              <ListItemAvatar>
                <Avatar src={item.imgSrc} alt={item.label.toLowerCase()} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {item.label}
                  </Typography>
                }
                secondary={
                  <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                    <LinearProgress
                      variant="determinate"
                      value={item.value === null ? 0 : item.value ? 100 : 0}
                      sx={{
                        height: 10,
                        borderRadius: 10,
                        flexGrow: 1,
                        marginRight: 2,
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: item.value === null ? 'grey' : item.value ? '#3ff107' : '#000',
                        },
                      }}
                    />
                    <Typography variant="body2" color="textSecondary">
                      {item.value === null ? 'Loading...' : item.value ? <CheckCircleIcon color='success' /> : <ScheduleOutlinedIcon color='warning' />}
                    </Typography>
                  </div>
                }
              />
              <IconButton onClick={() => handleDrawerOpen(item)}>
                <InfoIcon sx={{ color: 'black' }} />
              </IconButton>
            </ListItem>
          ))}
        </List>
      
      <Modal
        
        open={drawerOpen}
        onClose={handleDrawerClose}
        sx={{
          '& .MuiDrawer-paper': {
            borderRadius: '20px 20px 0 0', // Top left, top right, bottom right, bottom left
            display: 'flex',
           
            alignItems: 'center',
            padding: 0,
          },
        }}
      >
        <Box
          sx={style}
          role="presentation"
        >
  
  <div style={{ position: 'relative', width: '100%' }}>
        <img src={selectedItem?.image1} alt={selectedItem?.label.toLowerCase()} style={{ width: '100%', height: 'auto', borderRadius: 10 }} />
        <div style={{
          position: 'absolute',
          top: 0, // Position at the top
          left: 0, // Position to the left
          backgroundColor: 'rgba(255, 255, 255, 0.5)', // Optional: add background color for better readability
          padding: '8px', // Add some padding around the text
        }}>
          <Typography variant="h6" fontWeight={900}>
            {selectedItem?.label}
          </Typography>
        </div>
        {/* Additional content for the drawer, possibly using the 'selectedItem' state */}
      </div>
          <Divider />
          <List sx={{ width: '100%', maxWidth: 380, bgcolor: 'background.paper' }}>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon />
              </ListItemIcon>
              <ListItemText sx={{fontWeight: 600}} primary={selectedItem?.label} secondary={selectedItem?.value ? 'Completed' : 'Not Completed'} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="fertilizing" >
                 <img src={selectedItem?.imgSrc} alt={selectedItem?.label.toLowerCase()} style={{ width: '50px', height: '50px' }} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
           
            </List>
            <Divider />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2}}>
             
              <Typography variant="body1" sx={{ p: 2 }}>
                {selectedItem?.about}
              </Typography>
            </Box>
          {/* ... more detailed content based on the selected item ... */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: 2 }}>
          <Button onClick={handleDrawerClose} sx={{ backgroundColor: '#077336', color: 'white', fontWeight: 700, alignSelf: 'right' }}>Close</Button>
          </Box>
        </Box>
      </Modal>
    </div>
    )}
    
  </>
  )
}



export default Process

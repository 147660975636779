import React, { useState, useEffect, useContext } from 'react';
import { MobileStepper, Paper, Typography, Button, Box, Chip, Pagination,  Drawer } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import SwipeableViews from 'react-swipeable-views';
import { UPDATE_USER_PROF, GET_USER } from '../../../context/gqllogics';
import { UserContext } from '../../../context/usercontext';
import { useMutation , } from '@apollo/client';
import { useNavigate } from 'react-router-dom';


const steps = [
    {
      label: "Welcome to Koffieland?",
      content: "Your gateway to the digital bean economy.",
      imgPath: "https://storage.googleapis.com/app_darkendimg/assets/roasterbean.webp",
    },
   
    {
      label: "Mint Coffee Tokens",
      content: "Add Value with every batch roasted.",
      imgPath: "https://storage.googleapis.com/app_darkendimg/lilbeanie.png",
    },
    {
      label: "Smart Coffee",
      content: "Roaster make the world go around.",
      imgPath: "https://storage.googleapis.com/app_darkendimg/newton.png",
    },
    {
      label: "Market Place",
      content: "Invest in green beans, and join us as we roast and retail premium coffee. Share in the profits with our farmers, disrupt the status quo, and help us reshape the course of history. Your choice can lead to global change.",
      imgPath: "https://storage.googleapis.com/app_darkendimg/assets/jetset.png",
    },
    
    {
      label: "Disrupt",
      content: "As a Koffielander, you add value to every bean, delivering higher returns to farmers and taking the <b>'BIG'</b> out of big coffee.",
      imgPath: "https://storage.googleapis.com/app_darkendimg/assets/bikerbean.png",
    }
  ];
  

function RoastOn( redirectUrl ) {
  const { user } = useContext(UserContext);
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  //navigate to logic page after onboarding
  const goToLogic = () => {
    navigate('/logic');
  }

  //update user profile and refetch user data after onboarding to check if user has completed onboarding

 const [updateUserprof] = useMutation(UPDATE_USER_PROF, {
    refetchQueries: [{ query: GET_USER, variables: { id: user.id } }],
  });
  
  

  
  const handleComplete = async () => {
    setOpen(false);
  
    try {
      await updateUserprof({
        variables: {
          query: { _id: user.id }, // The query to find the user by ID
          set: { isOnboard: true }, // The fields you want to update
        },
      });
  
      console.log("User onboard status updated successfully.");
      // Direct redirection after the mutation
      goToLogic();
    } catch (error) {
      console.error("Error updating user profile:", error);
    }
  };
  
  
  
  
  
  const handleClose = () => {
    setOpen(false);
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ flexGrow: 1, height: '100hv', }}>
    <Drawer
 anchor="right"
 open={open}
 onClose={handleClose}
 sx={{ 
   height: '100vh', 
   zIndex: 1501,  // Set higher than the bottom navigation
   '& .MuiPaper-root': { 
     width: '100%', // Ensures the drawer covers the full height
     
   },
 }}
>
 <SwipeableViews
   index={activeStep}
   onChangeIndex={handleStepChange}
   enableMouseEvents
   containerStyle={{ height: 'calc(100% - 0px)' }} // Adjust height to account for pagination and skip chip
 >
   {steps.map((step, index) => (
     <div key={step.label} style={{ backgroundColor: '#ebcf0', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
       <Box square elevation={0} sx={{  flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingRight:1, paddingLeft: 2, paddingTop: 25  }}>
        
         {step.imgPath && <img src={step.imgPath} alt={step.label} style={{ width: '100%', maxHeight: '60vh', objectFit: 'contain' }} />}
         <Typography variant="h5" sx={{ mb: 1, fontWeight: 700 }}>{step.label}</Typography>
         <Typography variant="h6" sx={{ mb: 2 }}>{step.title}</Typography>
         <Typography sx={{ mb: 2 }}><p dangerouslySetInnerHTML={{ __html: step.content }} /></Typography>
       </Box>
     </div>
   ))}
 </SwipeableViews>
 <Box sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, py: 2,  }}>
   <Pagination count={maxSteps} page={activeStep + 1} onChange={(_, value) => handleStepChange(value - 1)} sx={{ justifyContent: 'center', display: 'flex' }} />
   <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
     <Chip label={`Skip ${activeStep + 1} of ${maxSteps}`} onClick={handleComplete} />
   </Box>
 </Box>
</Drawer>
</Box>
  );
}

export default RoastOn;

import React, { useContext, useCallback } from "react";
//import styles from "./styles.module.scss";
import { Web3ModalContext } from "./Web3ModalProvider";
import Button from '@mui/material/Button';



const Wallet = () => {

  
  function ellipseAddress(address, width = 4) {
    return `${address?.slice(0, width + 2)}...${address?.slice(-width)}`;
  }

  const { account, connect, disconnect } = useContext(Web3ModalContext);

  const handleConnectWallet = useCallback(() => {
    connect().catch((error) => {
      window.location.href = 'metamask: ';
        if (error.message === 'Modal closed by user') {
            console.log('User closed the modal.');
        } else {
            // Handle other errors here
            console.error(error);
        }
    });
}, [connect]);

  const handleDisconnectWallet = useCallback(() => {
    disconnect();
  }, [disconnect]);
  const accountLabel = !account ? "Connect Wallet" : ellipseAddress(account);

  return (
    <nav >
     
     <div >
    <span>
     
     
      <Button  label={accountLabel}  sx={{ backgroundColor: 'transparent', borderColor: '#32a852',borderWidth: '1px', borderRadius: 5 }} onClick={!account ? handleConnectWallet : handleDisconnectWallet}>
     {accountLabel}
      </Button>
    </span>
  </div>
    </nav>
  );
};

export default Wallet;

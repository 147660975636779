import React, { useEffect, useState, useContext,  } from 'react';
import { useQuery, useMutation , gql} from '@apollo/client';
import { Box, Card, CardContent, Typography, List, ListItem, ListItemText, Dialog, Chip, ListItemSecondaryAction , useMediaQuery, DialogContent } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import RenewBeans from '../mods/dialogue/renewbeans';
import Progress from '../mods/progress';
import { UserContext } from '../../context/usercontext';
import {   GET_USER, UPDATE_USER_PROF } from '../../context/gqllogics';
import { makeStyles } from '@mui/styles';
import { GET_CART, DELETE_ONE_ITEM, } from './Bag';
import Heart from '../mods/displays/heart'; // Import
import { Avatar } from '@mui/material';
import { set } from 'lodash';
import { BagContext, useBag, } from '../../context/BagContext';
import confetti from 'canvas-confetti';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import kcoin from '../../images/kcoin.png';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles(() => ({
  root: {
    padding: '10px',
    borderRadius: 20,
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',  // This is an example of a light top shadow style
  },
  content: {
    padding: 10,
    spacing: 8,
  },
  brandCardHeader: {
    fontSize: 25,
    fontWeight: 600,
    textAlign: 'left',
    margin: '0px' /* or a smaller value than what's currently set */
  },
  textInfoContent: {
    // Add styles for TextInfoContent here
  },
  button: {
    backgroundColor: '#077336',
    color: '#000000',
    borderRadius: 20,
    padding: 10,
    fontWeight: 600,
    border: 'none',
    width: '50%',
    height: 40,
    marginTop: 1,
    '&:hover': {
      backgroundColor: '#3ff107',
    },
  },
}));

const UPDATE_CART_MUTATION = gql`
mutation UpdateOneCart($query: CartQueryInput, $set: CartUpdateInput!) {
  updateOneCart(query: $query, set: $set) {
    userID
      beanies
      productId
      name
      _id
      price
      imageurl
      quantity
      options {
        size
        color
      
    }
  }
}
`;

function createCoffeeBeanConfetti(options = {}) {
  const defaults = {
    scalar: 1.5,
    spread: 80,
    particleCount: 150,
    origin: { y: -0.1 },
    startVelocity: -40,
    zIndex: 1500,
  };

  // Merge provided options with defaults
  const mergedOptions = { ...defaults, ...options };

  // Define the coffee bean shape as an array of coordinate pairs
  const coffeeBeanShape = [
    [0.1, 0.1],
  [0.2, 0.2],
    
  ];

  confetti({
    ...mergedOptions,
    shape: coffeeBeanShape, // Use the custom coffee bean shape
    colors: ['#704214', '#704214', '704214']
  });
}


const PaymentSuccess = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const sessionId = new URLSearchParams(location.search).get("session_id");
  const classes = useStyles();
  const [lineItems, setLineItems] = useState([]);
  const [open, setOpen] = useState(true);
  const [createConfetti, setCreateConfetti] = useState(false); 
  const [hearts, setHearts] = useState([]);
 
  useEffect(() => {
    const interval = setInterval(() => {
      const newHeart = {
        id: Math.random(),
        left: Math.random() * window.innerWidth,
        top: window.innerHeight,
      };
      setHearts((prevHearts) => [...prevHearts, newHeart]);

      setTimeout(() => {
        setHearts((prevHearts) =>
          prevHearts.filter((heart) => heart.id !== newHeart.id)
        );
      }, 2000);
    }, 200);

    return () => clearInterval(interval);
  }, []);

  const beanUrl = 'https://storage.googleapis.com/app_darkendimg/assets/madbean.png';
  
  
  const totalAmount = lineItems.reduce((acc, item) => acc + item.amount, 0);
  
  const goToProfile = () => {
    
    setLineItems([]);
    
    navigate('/chat');
  };

  const { data: cartData, loading: cartLoading, error: cartError } = useQuery(GET_CART, {
    variables: { userID: user.id },
    skip: !user.id
  });

  const { data: userData, loading: userLoading, error: userError } = useQuery(GET_USER, {
    variables: { _id: user.id }
  });

  useEffect(() => {
    if (cartData && cartData.carts) {
      const newLineItems = cartData.carts.map(item => ({
        description: item.name,
        amount: item.price * item.quantity
      }));
      setLineItems(newLineItems);
    }
  }, [cartData]);

const [deleteOneItem] = useMutation(DELETE_ONE_ITEM);
  const [updateUserProf] = useMutation(UPDATE_USER_PROF);
  const [updateCart] = useMutation(UPDATE_CART_MUTATION,
    {
      refetchQueries: [{ query: GET_CART, variables: { userID: user.id , isPaid: false } }]
    }
  );

  const existingBeans = userData?.userprof?.beanCount || 0;
const rewards = cartData?.carts.map((item) => item.beanies);
const totalRewards = rewards && rewards.length > 0
? rewards.reduce((acc, item) => acc + item, 0)
: 0;
const newbeanCount = existingBeans + totalRewards;

//filet cart items by isPaid = false
const cartItems = cartData?.carts.filter((item) => !item.isPaid);

const handleRedeem = async (cartId) => {
  try {
    // Update the user's bean count first
    await updateUserProf({
      variables: {
        query: { _id: user.id },
        set: { beanCount: newbeanCount }
      }
    });

    if (cartData && cartData.carts) {
      // Update the cart items to mark them as redeemed
      await Promise.all(
        cartData.carts.map((cartItem) =>
          updateCart({
            variables: {
              query: { _id: cartItem._id, isPaid: false},
              set: { isPaid: true, timeStamp: new Date().toISOString()}
            }
          })
        )
      );
    }
    // Show the bean rain dialog
   setCreateConfetti(true);

   // Trigger the confetti animation with the custom coffee bean shape
   createCoffeeBeanConfetti();

   // Hide the bean rain dialog after 1 second
   setTimeout(() => {
    setCreateConfetti(false);
    
   }, 3000)
    ;
  goToProfile();
    
    
  } catch (error) {
    console.error("Error redeeming cart:", error);
  }
};

const isSmartCitizen = userData?.userprof?.beanCount >= 4000;

  if (cartLoading || userLoading) {
    return <Progress />;
  }

  if (cartError || userError) {
    return <Typography>Error loading data: {cartError?.message || userError?.message}</Typography>;
  }

  

  return (

<React.Fragment>
    {isMobile && (
     
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
     
   
      {userData && userData.userprof && (
        <>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, flexDirection:  'column' }}>
            <Typography variant="h5"  sx={{ ml: 2, fontWeight: 700 , color: '#000', alignSelf: 'center' }}>
            Congratulations!
            </Typography>
            <Typography variant="body1"  sx={{ ml: 2, fontWeight: 600 , color: '#000', alignSelf: 'center' }}>
              Claim your Digi Beans below and start earning rewards!
            </Typography>
            </Box>
<Box sx={{ display: 'flex', justifyContent: 'space-between' , mt: 0, mb: 2, padding: 5 }}>
{isSmartCitizen ? (
  <Card
    sx={{
      flex: 1,
      height: 100,
      borderRadius: 4,
      overflow: 'hidden',
     alignItems: 'center',
     alignContent: 'center',

      color: 'white',
    }}
  >
    <CardContent
      sx={{
        
        justifyContent: 'space-between',
        alignItems: 'center',
     alignContent: 'center',
        padding: 2,
        backgroundColor: '#077336',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar src={userData?.userprof.imageurl} alt="bean" width={30} />
        <Typography
          variant="caption"
          sx={{ fontWeight: 700, marginRight: '8px' }}
        >
          {userData?.userprof?.Nickname} 
        </Typography>
        <Typography
          variant="caption"
          sx={{ fontWeight: 400, marginRight: '8px' }}
        >
          Citizen
        </Typography>
       
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          variant="caption"
          sx={{ fontWeight: 400, marginRight: '8px' }}
        >
          ID# {userData?.userprof._id.substring(0, 11)}
        </Typography>
        <Typography variant="caption" sx={{ fontWeight: 700, marginRight: '8px'  }}>
          {userData?.userprof?.beanCount}
        </Typography>
        <img src='https://storage.googleapis.com/app_darkendimg/assets/brkoin.png' alt="Bean" width={30} />
      </Box>
    </CardContent>
  </Card>
) : (
  <Card
    sx={{
      display: 'flex',
      height: 100,
      borderRadius: 2,
      overflow: 'hidden',
    }}
  >
    <CardContent
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        padding: 2,
        backgroundColor: '#addeb8',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar src={userData?.userprof.imageurl} alt="bean" width={30} />
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ fontWeight: 400, marginRight: '8px' }}
        >
          {userData?.userprof.Nickname}
          Bean Card
        </Typography>
       
      
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ fontWeight: 800, marginRight: '8px' }}
        >
          ID# {userData.userprof._id.substring(0, 11)}
        </Typography>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ marginRight: '4px' }}
        >
         {userData.userprof.beanCount}
        </Typography>
        <img src='https://df.com' alt="Bean" width={10} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center'
      
    }}>
        
        

        
        
        </Box>
    </CardContent>
  </Card>
)}
</Box>
</>
      )}

<Card >
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      
           
            </Box>
            <List>
              {cartItems.map((item, index) => (
                <ListItem key={index} divider={index < cartItems.length - 1}>
                  <ListItemText
                    primary={item.name}
                    secondary={`$${(item.price * item.quantity).toFixed(2)}`}
                  />
                  <ListItemSecondaryAction>
                    <Chip
                      label={`x${item.beanies}`}
                      sx={{ backgroundColor: '#077336', color: 'white'}}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
        
        
        <Box sx={{ display: 'flex', maxWidth: '400px', padding: 0 }}>
        <div className="App">
      {hearts.map((heart) => (
        <Heart key={heart.id} style={{ left: heart.left, top: heart.top }} beanUrl={beanUrl} />
      ))}
    </div> 
        <Typography variant="body2" >
              Your total: ${totalAmount.toFixed(2)}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', ml: 2 }}>
            <Typography variant='h6' sx={{ fontWeight: 700 , }}>{totalRewards} Tokens</Typography> 
            </Box>
      </CardContent>
    </Card>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      
            <button className={classes.button} onClick={handleRedeem} variant="contained"  sx={{ ml: 2 }}>
            <Typography variant='body1' sx={{ fontWeight: 500 , }}>  Redeem {totalRewards}k tokens </Typography> 
            </button>
          </Box>
          
    </Dialog>
    
    )
  }

{userData && userData.userprof && (
        <>
       <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, flexDirection:  'column' }}>
            <Typography variant="h5"  sx={{ ml: 2, fontWeight: 700 , color: '#000', alignSelf: 'center' }}>
            Congratulations!
            </Typography>
            <Typography variant="body1"  sx={{ ml: 2, fontWeight: 600 , color: '#000', alignSelf: 'center' }}>
              Claim your Digi Beans below and start earning rewards!
            </Typography>
            </Box>
<Box sx={{ display: 'flex', justifyContent: 'space-between' , mt: 0, mb: 2, padding: 5 }}>
{isSmartCitizen ? (
   <Card
   sx={{
     flex: 1,
     borderRadius: 4,
     overflow: 'hidden',
     width: '100%',
     color: 'white',
   }}
 >
   <CardContent
     sx={{
       display: 'grid',
       gridTemplateColumns: '1fr 1fr',
       gridTemplateRows: 'auto auto auto',
       gap: '8px',
       backgroundColor: '#077336',
       padding: 2,
     }}
   >
     <Box sx={{ gridColumn: '1 / 2', gridRow: '1' }}>
       <Typography variant="h6" sx={{ fontWeight: 700 }}>
         Citizen
       </Typography>
     </Box>

     <Box sx={{ gridColumn: '1 / 2', gridRow: '2', display: 'flex', alignItems: 'center' }}>
       <Avatar src={userData?.userprof.imageurl} alt="bean" sx={{ marginRight: '8px' }} />
       <Typography variant="body2" sx={{ fontWeight: 700 }}>
       {userData?.userprof.Nickname}
       </Typography>
     </Box>

     <Box sx={{ gridColumn: '1 / 2', gridRow: '3' }}>
       <Typography variant="body2" sx={{ fontWeight: 400 }}>
       ID# {userData?.userprof._id.substring(0, 11)}
       </Typography>
     </Box>

     <Box sx={{ gridColumn: '2 / 3', gridRow: '1 / 4', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'space-between' }}>
       <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
         <Typography variant="body2" sx={{ fontWeight: 700, marginRight: '8px' }}>
           {userData?.userprof.beanCount}
         </Typography>
         <img src={kcoin} alt="Bean" width={30} />
       </Box>
      <img src='https://storage.googleapis.com/app_darkendimg/assets/bcardwhite.png'alt="Bean" width={60} style={{ alignSelf: 'flex-end' }} />
     </Box>
   </CardContent>
 </Card>
) : (
  <Card
    sx={{
      display: 'flex',
      height: 100,
      borderRadius: 2,
      overflow: 'hidden',
    }}
  >
    <CardContent
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        padding: 2,
        backgroundColor: '#addeb8',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar src={userData?.userprof.imageurl} alt="bean" width={30} />
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ fontWeight: 400, marginRight: '8px' }}
        >
          {userData?.userprof.Nickname}
          Bean Card
        </Typography>
       
      
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ fontWeight: 800, marginRight: '8px' }}
        >
          ID# {userData.userprof._id.substring(0, 11)}
        </Typography>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ marginRight: '4px' }}
        >
         {userData.userprof.beanCount}
        </Typography>
        <img src='https://df.com' alt="Bean" width={10} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center'
      
    }}>
        
        

        
        
        </Box>
    </CardContent>
  </Card>
)}
</Box>
</>
      )}


        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <List>
              {cartItems.map((item, index) => (
                <ListItem key={index} divider={index < cartItems.length - 1}>
                  <ListItemText
                    primary={item.name}
                    secondary={`$${(item.price * item.quantity).toFixed(2)}`}
                  />
                  <ListItemSecondaryAction>
                    <Chip
                      label={`x${item.beanies}`}
                      sx={{ backgroundColor: '#3ff107', color: 'black'}}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
           
            </Box>
           
        
        
       
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', ml: 2 }}>
            <Typography variant='h6' sx={{ fontWeight: 700 , }}>{totalRewards} tokens</Typography> 
            </Box>
     

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      
            <button className={classes.button} onClick={handleRedeem} variant="contained"  sx={{ ml: 2 }}>
            <Typography variant='body1' sx={{ fontWeight: 500 , }}>  Redeem {totalRewards}k tokens </Typography> 
            </button>
          </Box>
          
           

    
</React.Fragment>
  );
};

export default PaymentSuccess;

import * as React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import Cloud from '@mui/icons-material/Cloud';
import { Avatar, Box, Card, Chip ,Popover, Button} from '@mui/material';
import Display from '../profile/settings/displaymode';
import { useNavigate } from 'react-router-dom';
import { Home, LocalMallOutlined, NaturePeopleOutlined, PeopleOutlineOutlined,   } from '@mui/icons-material';
import LogoutButton from '../profile/logout';
import klogo from '../../images/kcoin.png';
import SideDrop from '../../pages/desk/accmenu';
import { useBag } from '../../context/BagContext';
//import  UserContext  from '../../context/usercontext';
import { useState, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
//import BagIcon from '../finance/BagIcon';
import { UserContext } from '../../context/usercontext';
import beans from '../../images/coffeebeans.png';
import Wallet from '../../constants/wallet';
import LocalCafeOutlinedIcon from '@mui/icons-material/LocalCafeOutlined';
import beanbag from '../../images/beanbag.png';
import kcoin from '../../images/kcoin.png';
import kbean from '../../images/brkoin.png';
import StorefrontIcon from '@mui/icons-material/Storefront';
import eth from '../../images/eth.svg';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import { useBeanBag } from '../../context/beanBagContext';
import bean from '../../images/brkoin.png';
import PaymentIcon from '@mui/icons-material/Payment';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import { useEffect, useRef  } from 'react';
import ToolTip from '@mui/material/Tooltip';
import { set } from 'lodash';
import { Snackbar } from '@mui/material';
import lovekoffie from '../../images/lovekoffie.webp';
import Modal from '@mui/material/Modal';
import PostAndUploadComponent from '../posts/post';
import BeanCount from '../mods/beancount';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    borderRadius: 5,
    boxShadow: 10,
    position: 'relative', // Add position relative for absolute positioning of ribbon
  },
  logo: {
    width: 50,
    borderRadius: 0,
  },
  ribbon: {
    position: 'absolute',
    bottom: 5, // Adjust the position of the ribbon vertically
    left: 50, // Adjust the position of the ribbon horizontally
    backgroundColor: '#d1f0d2', // Adjust the background color of the ribbon
    padding: theme.spacing(.05),
    borderRadius: '5px 5px 5px 0px', // Adjust border radius for the ribbon
    borderWidth: 1, // Adjust border width
    borderStyle: 'solid', // Adjust border style
    borderColor: '#fff', // Adjust border color
    
   
  },
  ribbonText: {
    color: 'black', // Adjust the text color of the ribbon
    fontWeight: 900, // Adjust the font weight of the ribbon text
    fontSize: 10, // Adjust the font size of the ribbon text
  },
  tooltipContent: {
    padding: theme.spacing(2),
  },
}));

const BagIcon = () => {
  return (
    
    <img src={beanbag} alt="koffie" width={20} />
  
  );
};
const KcoinIcon = () => {
  return (
    <img src='https://storage.googleapis.com/app_darkendimg/assets/brkoin.png' alt="koffie" width={20} />
  );
};

const EthIcon = () => {
  return (
    <img src='https://storage.googleapis.com/app_darkendimg/assets/brkoin.png' alt="koffie" width={20} />
  );
};

const MarketIcon = () => {
  return (
    <img src='https://storage.googleapis.com/app_darkendimg/assets/marketgreen.png' alt="koffie" width={20} />
  );

};

const KoffiehandIcon = () => {
  return (
    <img src={bean} alt="koffie" width={20} />
  );
};

const KoffieIcon = () => {
  return (
    <img src={klogo} alt="koffie" width={20} />
  );
};

const path = [
  '/land',
  '/explore',
  '/chat',
  '/main',
  '/allkoffie',
  '/kcoin',
  '/middle',
  '/chron',
  '/user-market',
  '/reviews',
  '/store',
  '/post',
  '/privacy-policy',
  '/terms-of-service',
  // Add more mappings as needed
];


export default function LeftBar() {
  const navigate = useNavigate();
  
  const [value, setValue] = React.useState(0); // Add this line to define the 'value' state
  const { user } = useContext(UserContext);
  const { Nickname, beanCount, imageurl } = user.customData
  const classes = useStyles();
  const { itemCount } = useBag();
  const [expanded, setExpanded] = useState(false);
  const [postSubmitted, setPostSubmitted] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [step, setStep] = useState(0);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const steps = [
    {
      description: "Welcome to Bean Town! Click 'Next' to continue.",
      path: '/explore',
    },
    {
      description: "Explore the different states of Koffie in Koffie States.",
      path: '/allkoffie',
    },
    {
      description: "Discover various Koffie products in the Market Place.",
      path: '/user-market',
    },
    {
      description: "Read fascinating stories in the Koffie Chronicles.",
      path: '/chron',
    },
  ];

  const handleOpenModal = () => {
    setExpanded(true);
  };

  const handleCloseModal = () => {
    setExpanded(false);
  };

  useEffect(() => {
    if (postSubmitted) {
      // Close the modal after post is submitted
      setExpanded(false);
      
      setPostSubmitted(false); // Reset for next time
    }
  }, [postSubmitted]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNext = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    } else {
      handleClose();
    }
  };

  const handleSkip = () => {
    handleClose();
    navigate(steps[step + 1].path);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setStep(0);
    setMenuPosition({
      top: event.currentTarget.offsetTop + event.currentTarget.offsetHeight,
      left: event.currentTarget.offsetLeft,
    });
  };

  useEffect(() => {
    // Trigger popover on page load
    const menuItem = document.getElementById(`menu-item-${step}`);
    if (menuItem) {
      handleMenuClick({ currentTarget: menuItem });
    }
  }, []); 

  const open = Boolean(anchorEl);
  const id = open ? 'menu-popover' : undefined;

  const { itemCount1 } = useBeanBag();

  const handleNavigate = (path) => {
    navigate(path);
  }
const handlelogoClick = () => {
  navigate('/bag');
}




const handleBeanClick = () => {
  navigate('/beanbag');
}




const content = () => {
  switch (null) {
    case 0:
      return <Typography>Step 1: Welcome to the guide! Click "Next" to continue.</Typography>;
    case 1:
      return <Typography>Step 2: Here's an overview of the menu.</Typography>;
    case 2:
      return <Typography>Step 3: Click on a menu item to learn more.</Typography>;
    default:
      return null;
  }
};


  return (
    <Box sx={{  }}>
      
     {user && (   
    <Paper sx={{  }}>
      <MenuList sx={{padding: 0,}}>
        <MenuItem>
          <ListItemIcon>
          <Box className={classes.boxContainer}>
      <img src='https://storage.googleapis.com/app_darkendimg/assets/kland.png' alt={Nickname} className={classes.logo} />
      <Box className={classes.ribbon}>
        <div  className={classes.ribbonText}>
          BETA
        </div>
        
      </Box>
      <Typography variant="body1" color='text.secondary' sx={{fontWeight: 700,  }}>
         KOFFIELAND™
          </Typography>
    </Box>
          
          </ListItemIcon>
          <ListItemText
         onClick={() => handleNavigate(path[0])}>
          
          </ListItemText>
         
        </MenuItem>
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: menuPosition.top, left: menuPosition.left }}
      >
        <div className={classes.tooltipContent}>
          <Typography>{steps[step].description}</Typography>
          <div>
            <Button onClick={handleNext}>Next</Button>
            {step < steps.length - 1 && (
              <Button onClick={handleSkip}>Skip</Button>
            )}
          </div>
        </div>
      </Popover>
        
      <MenuItem onClick={() => handleNavigate(path[10])}>
          <ListItemIcon>
            <EthIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText >
          <Typography variant="body1" color="text.secondary" fontWeight={400}>
            Koffie Store
          </Typography></ListItemText>
          
        </MenuItem>
        <MenuItem onClick={() => handleNavigate(path[11])} >
          <ListItemIcon>
            <HistoryEduOutlinedIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText >
          <Typography variant="body1" color="text.secondary" sx={{fontWeight: 400}}>
            Lobby
          </Typography>
            </ListItemText> 
            
          <Typography variant="body1" color="text.secondary">
           
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => handleNavigate(path[1])}>
          <ListItemIcon>
            <PeopleOutlineOutlined fontSize="medium" />
          </ListItemIcon>
          <ListItemText >
          <Typography variant="body1" color="text.secondary"sx={{fontWeight: 400}}>
            Bean Town
          </Typography>
            </ListItemText> 
            
          <Typography variant="body1" color="text.secondary">
           
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => handleNavigate(path[4])} >
          <ListItemIcon>
            <NaturePeopleOutlined/>
          </ListItemIcon>
          <ListItemText >
          <Typography variant="body1" color="text.secondary" sx={{fontWeight: 400}}>
            Koffie States
          </Typography>
          </ListItemText>
         
        </MenuItem>
        <MenuItem onClick={() => handleNavigate(path[9])}>
          <ListItemIcon>
           <StorefrontIcon />
          </ListItemIcon>
          <ListItemText >
          <Typography variant="body1" color="text.secondary" sx={{fontWeight: 400}}>
            Places
          </Typography>
            </ListItemText> 
            
          <Typography variant="body1" color="text.secondary">
           
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => handleNavigate(path[7])}>
          <ListItemIcon>
            <HistoryEduOutlinedIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText >
          <Typography variant="body1" color="text.secondary" sx={{fontWeight: 400}}>
            Koffie Chronicles
          </Typography>
            </ListItemText> 
            
          <Typography variant="body1" color="text.secondary">
           
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => handleNavigate(path[3])}>
  <ListItemIcon>
    <LocalCafeOutlinedIcon />
  </ListItemIcon>
  <ListItemText >
    <Typography variant="body1" color="text.secondary" sx={{fontWeight: 400}}>
      Koffie Kit
    </Typography>
  </ListItemText>
 
</MenuItem>
<MenuItem onClick={() => handleNavigate(path[8])}>
  <ListItemIcon>
    <ShoppingBasketOutlinedIcon />
  </ListItemIcon>
  <ListItemText >
    <Typography variant="body1" color="text.secondary" sx={{fontWeight: 400}}>
      Market Place
    </Typography>
  </ListItemText>
  
</MenuItem>

        <MenuItem onClick={() => handleNavigate(path[6])}>
  <ListItemIcon>
  <img src={beanbag} alt="koffie" width={25} />
  </ListItemIcon>
  <ListItemText >
    <Typography variant="body1" color="text.secondary" sx={{fontWeight: 400}}>
      Bean Bag
    </Typography>
  </ListItemText>
  {itemCount1 > 0 ? (
    <>
    <Chip
      component={Link}
      to="/bean-bag"
      label={`${itemCount1} Beans Ahoy!`}
      onClick={handlelogoClick}
      icon={<KcoinIcon />}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 1, borderRadius: 5}}
    />
   
    </>
  ) : (
    <BeanCount/>
  )}
</MenuItem>

<MenuItem onClick={() => handleNavigate(path[5])}>
          <ListItemIcon>
            <img src='https://storage.googleapis.com/app_darkendimg/assets/thebec.webp' alt="koffie" width={30} />
          </ListItemIcon>
          <ListItemText >
          <Typography variant="body1" color="text.secondary"sx={{fontWeight: 400}}>
           BEC
          </Typography>
          </ListItemText>
          
        </MenuItem>
        

        <MenuItem >
          <button onClick={handleOpenModal} style={{ color: '#fff', border: 'none', height: '30px', width: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 1, borderRadius: 10, backgroundColor: '#077336', }}>
            <Typography variant="body1" color="white" sx={{fontWeight: 400}}>
              Add a Post
            </Typography>
          </button>
          </MenuItem>

        <Modal open={expanded} onClose={handleCloseModal} sx={{ padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 10 }}>
        <Box style={{  width: 400,  justifyContent: 'center', alignItems: 'center', padding: 2, backgroundColor: '#fff', borderRadius: 10, border: 'solid 1px #077336' }}>
          <Typography variant="h5" component="div" sx={{ fontWeight: 800, textAlign: 'center', color: '#077336' }}>
            Add a Post
          </Typography>
          <PostAndUploadComponent setPostSubmitted={setPostSubmitted} handleCloseModal/>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 1, }}>
            <Typography variant="caption" color="text.secondary" sx={{fontWeight: 700}}>
              Powered by Java
            </Typography>
            
          </Box>
          

        </Box>
        
      </Modal>
       
        <Divider />
        
        <MenuItem>
        


       </MenuItem>
       <MenuItem>
          <ListItemIcon>
           <Display />
          </ListItemIcon>
         
        </MenuItem>
      <MenuItem>
                   <ListItemText onClick={() => handleNavigate(path[6])}>
          <Typography variant="h6" color="text.secondary">
            <LogoutButton />
          </Typography>
          </ListItemText>
          
        </MenuItem>
       
      </MenuList>
    </Paper>
    
     )}
     <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 1, }}>
     <MenuItem>
                   <ListItemText onClick={() => handleNavigate(path[12])}>
          <Typography variant="body2" color="text.secondary">
            Privacy Policy
          </Typography>
          </ListItemText>
          
        </MenuItem>
        <MenuItem>
                   <ListItemText onClick={() => handleNavigate(path[13])}>
          <Typography variant="body2" color="text.secondary">
           Terms of Service
          </Typography>
          </ListItemText>
          
        </MenuItem>
      
     <Typography variant="caption" color="text.secondary" sx={{fontWeight: 700}}>
      Powered by Java 
      </Typography  > <Avatar src="https://storage.googleapis.com/app_darkendimg/assets/blackjava.png" alt="koffie" sx={{ width: 40, height: 40, backgroundColor: 'transparent'  }} />
      <Typography variant="caption" color="text.secondary" sx={{fontWeight: 500}}>
        All rights reserved Koffieland™ 2024
      </Typography>
    </Box>

    </Box>
  );
}

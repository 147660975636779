import React, { useState, useEffect, useRef} from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Card, CardContent, Typography, Paper, Grid, Avatar, TextField, List, Drawer, CardActions, Button,CardMedia, Box, CardActionArea , Zoom, Fade, Chip, AvatarGroup} from '@mui/material';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/usercontext';
import { useContext } from 'react';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import TelegramIcon from '@mui/icons-material/Telegram';
import { useSpring, animated, a } from 'react-spring';
import beanify  from '../../images/beaniefy.png';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Chat, ChatBubble, ChatBubbleOutline, ChatBubbleOutlineOutlined, Close, ShowChartOutlined } from '@mui/icons-material';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { Facebook, Twitter, WhatsApp, LinkedIn, Instagram, Email } from '@mui/icons-material';
import { Icon } from '@mui/material';
import ClaimBeans from './dialogue/renewbeans';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import "../../constants/beanlike.css";
import { useNavigate } from 'react-router-dom';
import {LinearProgress} from '@mui/material';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';
import { ThumbUpAltOutlined } from '@mui/icons-material';

// Keyframe animation for burst effect
const burst = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
`;

const Image = styled.img`
  width: 18px;
  animation: ${props => props.animate ? `${burst} 0.3s ease-out forwards` : 'none'};
`;

const roastedCoffeeBeans = 'https://storage.googleapis.com/app_darkendimg/assets/brkoin.png';
const coffeebeans = 'https://storage.googleapis.com/app_darkendimg/assets/kbean.png';

const Alert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      
      {...props}
      sx={{
       
        height: 100,
        backgroundColor: 'transparent',
        
        borderRadius: '4px',
        
        // Increase specificity here if needed
        '&.MuiAlert-root': { // This targets the root class of MuiAlert
          // Place specific overrides here
        }
      }}
    />
  );
});


const GET_COMMENTS = gql`
  query GetComments($contentId: ObjectId!) {
  comments(query: { contentId: $contentId }) {
    
    _id
    content
    contentId
    contentType
    name
    image
    author
    
    
  }
   
}
`;

const GET_LIKES = gql`
  query GetLikes($contentId: ObjectId!) {
    likes(query: { contentId: $contentId }) {
  _id
    contentId
    contentType
    user
    contentCreator
    likecount
    avatar
    }
  }
`;



const CREATE_COMMENT_MUTATION = gql`
  mutation InsertComment($data: CommentInsertInput!) {
    insertOneComment(data: $data) {
      _id
    }
  }
`;

const CREATE_LIKE_MUTATION = gql`
  mutation InsertLike($data: LikeInsertInput!) {
    insertOneLike(data: $data) {
      _id
    }
  }
`;

const GET_BEAN_COUNT = gql`
  query GetBeanCount($_id: ObjectId!) {
    userprof(query: {_id: $_id}) {
      beanCount
      _id
    }
  }
`;

const DECREMENT_BEAN_COUNT = gql`
  mutation DecrementBeanCount($_id: ObjectId!, $beanCount_inc: Int!) {
    updateOneUserprof(query: { _id: $_id }, set: { beanCount_inc: $beanCount_inc }) {
      _id
      beanCount
    }
  }
`;

const INSERT_REPLY = gql`
  mutation INSERT_REPLY($data: ReplyInsertInput!) {
    insertOneReply(data: $data) {
      _id
      commentId
      text
      author
      createdAt
      Nickname
    }
  }
`;

const GET_REPLIES = gql`
  query GetReplies($commentId: ObjectId!) {
    replies(query: { commentId: $commentId }) {
      _id
      text
      author
      createdAt
      commentId
      Nickname
      imageurl
    }
  }
`;

function formatBeanCount(number) {
  if (number < 1000) {
    return number; // return the same number if less than 1000
  } else if (number < 1000000) { // for numbers between 1000 and 999,999
    return (number / 1000).toFixed(1) + 'k'; // one decimal place with 'k'
  } else {
    return (number / 1000000).toFixed(1) + 'M'; // one decimal place with 'M' for numbers 1,000,000 and above
  }
}


const SocialMediaShareButton = ({ platform, shareUrl, title, icon: Icon }) => {
  const generateShareLink = () => {
    switch (platform) {
      case 'facebook':
        return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
      case 'twitter':
        return `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(title)}`;
      case 'whatsapp':
        return `https://api.whatsapp.com/send?text=${encodeURIComponent(title + " " + shareUrl)}`;
      default:
        return '#';
        case 'linkedin': 
        return `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`;
        case 'instagram':
          return `https://www.instagram.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`;
        case 'email':
          return `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(shareUrl)}`;
    }
  };
  const shareLink = generateShareLink();
  return (
     
    <Grid item xs={3} style={{ textAlign: 'center' }}>
    <a href={shareLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
      <Icon style={{ fontSize: '40px' }} />
      <Typography variant="body2">{platform}</Typography>
    </a>
  </Grid>
    
  );
};


// Component to handle replies for each comment
const ReplySection = ({ commentId, author }) => {
  const { user } = useContext(UserContext); // Ensure consistent extraction of user
  const Nickname = user?.customData?.Nickname || 'Anonymous';
  const imageurl = user?.customData?.imageurl || '';
  const { data: repliesData, loading: repliesLoading, error: repliesError } = useQuery(GET_REPLIES, {
    variables: { commentId },
  });
  
  //timeAgo function
  function timeAgo(date) {
    const now = new Date();
    const secondsPast = (now.getTime() - date.getTime()) / 1000;
  }


  const [replyText, setReplyText] = useState('');
  const [insertReply] = useMutation(INSERT_REPLY);

  const handleReplySubmit = async () => {
    if (!replyText) return;

    try {
      await insertReply({
        variables: {
          data: {
            commentId,
            text: replyText,
            author, // Use the current user's name or ID
            Nickname,
            imageurl,
            createdAt: new Date().toISOString(),

          },
        },
        update: (cache, { data: { insertOneReply } }) => {
          const existingReplies = cache.readQuery({
            query: GET_REPLIES,
            variables: { commentId },
          });

          if (existingReplies && existingReplies.replies) {
            cache.writeQuery({
              query: GET_REPLIES,
              data: {
                replies: [...existingReplies.replies, insertOneReply],
              },
              variables: { commentId },
            });
          }
        },
      });

      setReplyText(''); // Clear the reply text input
    } catch (error) {
      console.error('Failed to submit reply:', error);
    }
  };


  if (repliesLoading) return <Typography>Loading replies...</Typography>;
  if (repliesError) return <Typography>Error loading replies.</Typography>;

  return (
    <Box sx={{ marginLeft: 0 ,

      backgroundColor: (theme) => theme.palette.background.default,
      color: (theme) => theme.palette.text.primary,    
    }}>
      {repliesData?.replies.map((reply) => (
        <Box key={reply._id} sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Avatar src={reply.imageurl} alt={reply.Nickname} />
          <Typography variant="body2" sx={{ fontWeight: 600 }}>{reply.Nickname}</Typography>
          
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>{timeAgo(new Date(reply.createdAt))}</Typography>
          </Box>
          <Typography variant="body2">{reply.text}</Typography>
        </Box>
      ))}

      {/* Reply Input Field */}
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, gap: 1 }}>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Write a reply..."
          value={replyText}
          onChange={(e) => setReplyText(e.target.value)}
          sx={{ flexGrow: 1 }}
        />
        <Button variant="contained" onClick={handleReplySubmit}>Reply</Button>
      </Box>
    </Box>
  );
};

function PCommentsSingle({ post }) {
    const { user } = useContext(UserContext);
    const { Nickname, imageurl } = user.customData; 
    const currentUrl = window.location.href;
const shareUrl = `${window.location.origin}/?redirect=${encodeURIComponent(currentUrl)}`;
  const title = 'Check out Koffieland!';
  const [beanCount, setBeanCount] = useState(0); // Initialize with 0 beans
  const [comment, setComment] = useState('');
  const [imageSrc, setImageSrc] = useState(coffeebeans); // State to manage image source
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerOpen1, setDrawerOpen1] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [drawerOpen2, setDrawerOpen2] = useState(false);
  const [drawerOpen3, setDrawerOpen3] = useState(false);
  const [totalLikeCount, setTotalLikeCount] = useState(0);
  const [props, set] = useSpring(() => ({
    transform: 'scale(1)',
   
  }));
  const [snackbarOpen, setSnackbarOpen] = useState(false);
 

  const toggleReplies = (commentId) => {
    setExpandedCommentId(commentId === expandedCommentId ? null : commentId);
  };
   // Animation styles
   const animationStyle = useSpring({
    to: { transform: 'scale(1.2)', color: 'grey' },
    from: { transform: 'scale(1)', color: 'black' },
    reset: animate,
    reverse: animate,
    onRest: () => setAnimate(false),
    setImageSrc: roastedCoffeeBeans
  });
  
  // Define your animation style for the Drawer
const drawerAnimation = useSpring({
  to: { opacity: drawerOpen2 ? 1 : 0, transform: drawerOpen2 ? 'translateY(0)' : 'translateY(100%)' },
  from: { opacity: 0, transform: 'translateY(100%)' },
});

  const { data: beanData, loading: beanLoading, error: beanError } = useQuery(GET_BEAN_COUNT, {
    variables: { _id: user.id },
  });
  const [decrementBeanCount] = useMutation(DECREMENT_BEAN_COUNT);
   

  //call keyboard
  const textareaRef = useRef(null);

  // Focus on the textarea when the drawer opens
  useEffect(() => {
    if (drawerOpen && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [drawerOpen]);

  useEffect(() => {
    if (beanData && beanData.userprof) {
      setBeanCount(beanData.userprof.beanCount);
    }
  }, [beanData]);

 // Modified handleLike to toggle animation
 const handleLikeClick = () => {
  setAnimate(true); // Start the animation
  handleLike(); // Your original logic to increment likeCount
  setTimeout(() => setAnimate(false), 20); // Optional: Reset animation state after 500ms
  setImageSrc(roastedCoffeeBeans); // Change the image source
};

const handleDrawerToggle = () => {
setDrawerOpen(!drawerOpen);
};
const handleDrawerToggle1 = () => {
  setDrawerOpen1(!drawerOpen1);
  };

  const handleDrawerToggle2 = () => {
    setDrawerOpen2(!drawerOpen2);
    };
    
const handleDrawerToggle3 = () => {
  setDrawerOpen3(!drawerOpen3);
  expandedCommentId && setExpandedCommentId(null);
  
  };

  
  const handleBounce = () => {
    set({ transform: 'scale(1.2)' });
    setTimeout(() => {
      set({ transform: 'scale(1)' });
    }, 200); // Reset to original scale after 2 seconds
  };

  const [createComment] = useMutation(CREATE_COMMENT_MUTATION);
  const [createLike] = useMutation(CREATE_LIKE_MUTATION);
  const { data: commentsData, loading: commentsLoading } = useQuery(GET_COMMENTS, {
    variables: { contentId: post._id }
  });
  
  const [expandedCommentId, setExpandedCommentId] = useState(null);
  const { data: likesData, loading: likesLoading, error: errorLikes } = useQuery(GET_LIKES, {
    variables: { contentId: post._id }
  });

   // Grouped Avatar Logic
   const avatars = likesData?.likes.map(like => like.avatar) || [];
   const maxDisplay = 5;  // Max number of avatars to show
   const displayAvatars = avatars.slice(0, maxDisplay);
   const extraAvatars = avatars.length - maxDisplay;

    const commentCount = commentsData?.comments.length;
    const likeCount = likesData?.likes.filter(like => like.contentId === post._id).length;
    
    useEffect(() => {
      if (!likesLoading && !errorLikes && likesData) {
        // Sum up all likeCount values
        const sumOfLikeCounts = likesData.likes.reduce((total, like) => total + (like.likecount || 0), 0);
        setTotalLikeCount(sumOfLikeCounts);
    
        console.log(`Total Like Count: ${sumOfLikeCounts}`);
      }
    }, [totalLikeCount, likesLoading, likesData, setTotalLikeCount]);
    
    
    const beansCount = commentCount * 3 + likeCount;
    const level = Math.floor(beansCount / 100);
    const color = level < 100 ? 'grey' : level === 1 ? 'grey' : 'grey';
    const handleComment = async () => {
      console.log("Current beanCount:", beanCount); // Debugging line
    
      if (beanCount < 1) {
        setShowDialog(true); // This will automatically show the dialog
        return;
      }
    
      try {
        // Decrement bean count first
        await decrementBeanCount({
          variables: { _id: user.id, beanCount_inc: -3 } // Assuming each comment costs 1 bean
        });
    
        // If decrement is successful, then decrement beanCount on the client side for immediate feedback
        setBeanCount((prevBeanCount) => prevBeanCount - 3); // Assuming you decrement by 1 for a comment
    
        // Then create comment
        await createComment({
          variables: {
            data: {
              content: comment,
              author: user.id, // the logged in user id
              contentId: post._id,
              contentType: 'koffie_post',
              contentCreator: post.userID,
              name: user.customData.Nickname ,
              image: user.customData.imageurl,
              createdAt: new Date().toISOString()

            }
          },
          update: (cache, { data: { insertOneComment } }) => {
            // If the data and data.comments exist, update the cache
            const existingComments = cache.readQuery({
              query: GET_COMMENTS,
              variables: { contentId: post._id }
            });
            setDrawerOpen(false);
            // Write the data back to the cache with the new comment added
            if (existingComments && existingComments.comments) {
              cache.writeQuery({
                query: GET_COMMENTS,
                data: {
                  comments: [...existingComments.comments, insertOneComment]
                },
                variables: { contentId: post._id }
              });
            }
          }
        });
    
        setComment('');
        setSnackbarOpen(true);
        
      } catch (error) {
        console.error('Failed to create comment:', error);
        // Optionally handle the error, such as showing an error message to the user
      }
    };
    
      

    const handleLike = async (likeCount) => {
      if (beanCount < likeCount) {
        setShowDialog(true); // This will automatically show the dialog
        return;
      }
    
      // Find the corresponding reward based on likeCount
      const reward = beanRewards.find(reward => reward.likeCount === likeCount);
    
      if (!reward) {
        console.error('No corresponding reward found for this like count:', likeCount);
        return;
      }
    
      try {
        // Decrement bean count first
        await decrementBeanCount({
          variables: { _id: user.id, beanCount_inc: -likeCount }
        });
    
        // If decrement is successful, then decrement beanCount on the client side for immediate feedback
        setBeanCount(beanCount - likeCount);
    
        // Then create like
        await createLike({
          variables: {
            data: {
              user: user.id, // the logged in user id
              contentId: post._id,
              contentType: 'koffie_post',
              contentCreator: post.userID,
              likecount: likeCount,
              avatar: reward.imgSrc // Use the matched avatar URL here
            }
          },
          update: (cache, { data: { insertOneLike } }) => {
            // If the data and data.likes exist, update the cache
            if (likesData && likesData.likes) {
              const updatedLikes = [...likesData.likes, insertOneLike];
              cache.writeQuery({
                query: GET_LIKES,
                data: { likes: updatedLikes },
                variables: { contentId: post._id, contentCreator: post.userID, user: user.id, likeCount: likeCount }
              });
            }
          }
        });
    
        setSnackbarOpen(true);
        handleDrawerToggle2();
    
      } catch (error) {
        console.error('Failed to handle like:', error);
        // Handle the error, such as resetting bean count, showing error message, etc.
      }
    };
    
    


 

const handleSnackbarClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setSnackbarOpen(false);
};   

const beanRewards = [
  { number: 1, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/farmerbean.png', likeCount: 1 },
  { number: 2, imgSrc: 'https://storage.googleapis.com/app_darkendimg/lilbean.png', likeCount: 5 },
  { number: 3, imgSrc: 'https://storage.googleapis.com/app_darkendimg/lilbeanie.png', likeCount: 10 },
  { number: 4, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/purp.png', likeCount: 20 },
  { number: 5, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/tinbean.png', likeCount: 100 },
  { number: 6, imgSrc: 'https://storage.googleapis.com/app_darkendimg/youngbean.png', likeCount: 200 },
  { number: 7, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/trooper.png', likeCount: 300 },
  { number: 8, imgSrc: 'https://storage.googleapis.com/app_darkendimg/iceicebeaine.png', likeCount: 400 },
  { number: 9, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/beezy.png', likeCount: 500 },
  { number: 10, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/bikerbean.png', likeCount: 1000 },
  { number: 11, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/jetset.png', likeCount: 2000 },
  { number: 12, imgSrc: 'https://storage.googleapis.com/app_darkendimg/newton.png', likeCount: 3000 },
  { number: 13, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/royal.png', likeCount: 4000 },
  { number: 14, imgSrc: 'https://storage.googleapis.com/app_darkendimg/assets/madbean.png', likeCount: 5000 },
  { number: 15, imgSrc: 'https://storage.googleapis.com/app_darkendimg/bennyborer.png', likeCount: -1 },


  // Add more as needed
];

function timeAgo(date) {
  const now = new Date();
  const secondsPast = (now.getTime() - date.getTime()) / 1000;

  if (secondsPast < 60) {
    return `${Math.round(secondsPast)}s`;
  }
  if (secondsPast < 3600) {
    return `${Math.round(secondsPast / 60)}m`;
  }
  if (secondsPast <= 86400) {
    return `${Math.round(secondsPast / 3600)}h`;
  }
  if (secondsPast > 86400) {
    const day = date.getDate();
    const month = date.toDateString().match(/ [a-zA-Z]*/)[0].replace(" ", "");
    const year = date.getFullYear() === now.getFullYear() ? "" : ` ${date.getFullYear()}`;
    return `${day} ${month}${year}`;
  }
}

const renderMedia = (media, title, poster) => {
  if (media && media.length > 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          marginTop: 2,
          padding: 0,
        }}
      >
        {media.map((item, index) => {
          // Check if the item is a video file based on its extension
          const isVideo = /\.(mp4|webm|mov)$/i.test(item);

          return (
            <Box key={index} sx={{ marginBottom: '10px', width: '100%' }}>
              {isVideo ? (
                <video controls style={{ borderRadius: 10, width: '100%', padding: 0 }}>
                  <source src={item} type="video/mp4" />
                  Sorry, your browser doesn't support embedded videos.
                </video>
              ) : (
                <img
                  src={item}
                  alt={`${title} ${index + 1}`}
                  style={{
                    borderRadius: '10px',
                    padding: 0,
                    objectFit: 'contain',
                    width: '100%',
                    height: 'auto',
                  }}
                  // Fallback image on error
                  onError={(e) => {
                    e.target.onerror = null; // Prevent infinite loop if fallback image fails
                    e.target.src = '/path/to/fallback-image.png'; // Replace with your fallback image
                  }}
                />
              )}
            </Box>
          );
        })}
      </Box>
    );
  }

  return null; // Return null if there's no media
};


  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center',
      backgroundColor: (theme) => theme.palette.background.default,
      color: (theme) => theme.palette.text.primary,  
      boxShadow: 0,
     }}>
       <Snackbar 
  open={snackbarOpen} 
  autoHideDuration={1500} 
  onClose={handleSnackbarClose}
  anchorOrigin={{ vertical: 'center', horizontal: 'center' }} // Center the Snackbar
  sx={{ maxWidth: 100, bottom: '80px', fontWeight: 800, color: '#fff', backgroundColor: "#077336", borderRadius: 10, }} // Adjust the bottom position to be above the bean count
>
  <Typography 
    
    variant="caption" 
    fontWeight={800}
    component="div" 
    color="text.primary" 
    sx={{  fontWeight: 800, color: '#fff', backgroundColor: "#077336" }}
  >
    {beanCount}  <b>Beans left</b>
  </Typography>
</Snackbar>
         {showDialog && <ClaimBeans onClose={() => setShowDialog(false)} />}   
         <Drawer
  anchor="bottom"
  open={drawerOpen}
  onClose={handleDrawerToggle}
  transitionDuration={300} // Optional: adjust transition duration
  PaperProps={{
    sx: {
      height: '100vh',
      overflow: 'auto', // To handle scrolling content
      display: 'flex',
      flexDirection: 'column',
    }
  }}
>
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: 2 }}>
    <CloseIcon onClick={handleDrawerToggle} />
    <Chip onClick={handleComment} label="Post" sx={{ backgroundColor: '#077336', color: '#fff' }} />
  </Box>

  <div style={{ marginTop: 4, padding: 4 }}>
    <TextareaAutosize
      ref={textareaRef}
      style={{
        width: '80%',
        background: '#51b27182',
        color: '#000',
        padding: '10px',
        borderRadius: '5px',
        border: 'none',
        outline: 'none',
        margin: '1px',
        fontSize: '16px', // Prevent zoom on mobile
      }}
      aria-label="empty textarea"
      value={comment}
      onChange={(e) => setComment(e.target.value)}
      placeholder={`Comment on ${post.content}`}
    />
  </div>

  <Card key={post._id} sx={{ maxWidth: 400 }}>
    {/* ...existing content... */}
    <Snackbar open={snackbarOpen} autoHideDuration={1500} onClose={handleSnackbarClose}>
      <Alert sx={{ color: '#000' }}>
        <Typography variant="caption" sx={{ fontWeight: 800 }}>
          {beanCount}
        </Typography>
      </Alert>
    </Snackbar>

    <CardActions sx={{ display: 'flex', width: '100%' }}>
      <div style={props} onClick={handleLike}>
        <IconButton aria-label="add to favorites" sx={{ paddingBottom: 0 }} onClick={handleLikeClick}>
          <img src={imageSrc} alt="Beanie" width={15} />
        </IconButton>
      </div>
      <Typography>{formatBeanCount(totalLikeCount)} Likes, {commentCount} Comments</Typography>
    </CardActions>
  </Card>
</Drawer>

    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      
    <Drawer
      anchor="bottom"
      open={drawerOpen2}
      onClose={handleDrawerToggle2}
      sx={{
        '& .MuiPaper-root': {
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
        }
      }}
    >
      <animated.div style={drawerAnimation} >
    <Paper sx={{ my: 1, mx: 'auto', p: 2, width: '100%' , mb: 6 }}>
      
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
    <Box sx={{ paddingLeft: 2 ,paddingTop: 2, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%' }}>
      <Grid item xs={12} sm={12} md={12}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%' }}>
  <Chip
    avatar={<Avatar sx={{ width: 20, height: 20 }} src={beanify} />}
    label={<Typography variant="caption" sx={{ fontWeight: 600 }}>{beanCount}</Typography>}
    sx={{ borderRadius: '16px', padding: '2px 4px' }}
  />
</Box>
        
      </Grid>
      
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      <Grid item xs={8} sm={6} md={9}>
      <Typography variant="body1" sx={{ color: 'text.secondary', textAlign: 'center', fontWeight: 700 }}>
          Reward Koffielanders
        </Typography>
        <Typography variant="body2" sx={{ paddingLeft: 2, color: 'text.secondary', textAlign: 'center',  }}>
          Was this post helpful?  Give according to the value you got.
         
        </Typography>
      </Grid>
      <Grid item xs={4} sm={6} md={3}>
      <img src='https://storage.googleapis.com/app_darkendimg/assets/whitebackapp.png' alt="Beanie" width={200} />
      </Grid>
      </Box>
      {beanRewards.map((reward, index) => (
  <Grid 
    item 
    xs={.8} // For very small screens, each icon takes 2.4/12th (5 icons per row)
    sm={1} // For small screens, each icon takes 2.4/12th (5 icons per row)
    md={2.5} // For medium screens and above, each icon takes 2.4/12th (5 icons per row)
    key={index} 
    onClick={() => handleLike(reward.likeCount)}
    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
  >
    <Avatar sx={{ width: 50, height: 50 }} src={reward.imgSrc} />
    <Typography variant="caption" sx={{ fontWeight: 700, textAlign: 'center' }}>
      {reward.likeCount}
    </Typography>
  </Grid>
))}


    </Grid>
  </Paper>
  </animated.div>
    </Drawer>
    
    </Box>

    <CardActionArea sx={{ gap: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
  <CardActions sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
  
    {/* Like Button and Count */}
    <Box onClick={handleDrawerToggle2}  sx={{ display: 'flex', width: '100%', alignItems: 'center'}}>
      {!animate && (
        <IconButton aria-label="add to favorites" >
          <div >
            <img src={imageSrc} alt="Beanie" width={20} />
          </div>
        </IconButton>
      )}
      {animate && (
        
          <div>
            <IconButton aria-label="add to favorites" sx={{ paddingBottom: 0 }}>
              <Image src={imageSrc} alt="Beanie" animate={animate} />
            </IconButton>
          </div>
        
      )}
      <Typography variant="caption" color="text.secondary" sx={{ textAlign: 'center', mb: 1 }}>
      {formatBeanCount(totalLikeCount)} 
      </Typography>
    </Box>

    {/* Comment Button and Count */}
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' , display: 'flex', width: '100%' }}>
      <IconButton aria-label="add to favorites" sx={{ paddingBottom: 0 }}>
        <animated.div style={animationStyle} >
          <ChatBubbleOutlineOutlined  onClick={handleDrawerToggle}  sx={{width: 15}}/>
        </animated.div>
      </IconButton>
      <Typography variant="caption" color="text.secondary" sx={{ textAlign: 'center',mt: 1  }}>
        {commentCount}
      </Typography>
    </Box>
        {/* Avatar Group */}
        <Box sx={{ display: 'flex', textAlign: 'center', width: '100%' }}>
  <AvatarGroup
    max={5}
    sx={{
      marginTop: '0px',
      '& .MuiAvatar-root': {
        width: '25px',      // Set width of all avatars
        height: '25px',     // Set height of all avatars
        color: '#077336',      // Text color for avatars
        fontSize: '0.75rem', // Adjust font size for +X text
        border: '2px solid #000', // Custom border color
        backgroundColor: '#ebecf0', // Background color for avatars
      },
    }}
  >
    {avatars.map((src, index) => (
      <Avatar
        key={index}
        src={src}
      />
    ))}
  </AvatarGroup>
</Box>

    {/* Beans Progress Bar */}
    <Box sx={{ display: 'flex', textAlign: 'center', width: '100%' }}>
    <IconButton aria-label="add to favorites" sx={{ paddingBottom: 0,mb: 1 }}>
      <ShowChartOutlined sx={{ color: color }} />
      </IconButton>
      <Typography variant="caption" color="text.secondary" sx={{ textAlign: 'center', mt: 1 }}>
        {((commentCount * 3 + likeCount) < 100)
          ? `${commentCount * 3 + likeCount} `
          : `${Math.floor((commentCount * 3 + likeCount) / 100)} ${Math.floor((commentCount * 3 + likeCount) / 100) === 1 ? '' : ''}`}
      </Typography>
    </Box>

    {/* Share Button */}
    <Box onClick={handleDrawerToggle1} sx={{ textAlign: 'center', }}>
      <IconButton aria-label='share' sx={{ paddingBottom: 0, width: 15 }}>
        <ShareOutlinedIcon  sx={{  width: 20}}/>
      </IconButton>
      
    </Box>

  </CardActions>
</CardActionArea>

<Timeline 
      sx={{ 
        [`& .MuiTimelineItem-root:before`]: { flex: 0, padding: 0 }, 
        borderBottom: 'solid 1px #ebecf0' 
      }}
    >
      {commentsData?.comments.map((comment) => (
        <TimelineItem key={comment._id}>
          <TimelineSeparator>
            {comment.image && <Avatar alt={comment.name} src={comment.image} sx={{ width: 25, height: 25 }} />}
          </TimelineSeparator>
          <TimelineContent sx={{ padding: 0 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 0, gap: 2, justifyContent: 'space-between' }}>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>{comment.name}</Typography>
              <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 400 }}>
                {timeAgo(new Date(comment.createdAt))}
              </Typography>
            </Box>
          
            {/* Comment Content */}
           
              <Box sx={{ mt: 1 }}>
                <Typography variant="body2">{comment.content}</Typography>
                
              </Box>
            

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
              <IconButton aria-label="add to favorites" sx={{ padding: 0 }}>
                <ChatBubbleOutlineOutlined sx={{ width: 15 }} onClick={handleDrawerToggle3}/>
              </IconButton>
              <IconButton aria-label="Like" sx={{ padding: 0 }}>
                <ThumbUpAltOutlined  sx={{ width: 15 }} onClick={handleDrawerToggle2}/>
              </IconButton>
            </Box>
            {/* Toggle Replies Section */}
            <Button
              variant="text"
              size="small"
              onClick={() => toggleReplies(comment._id)}
              sx={{ textTransform: 'none', marginTop: 1, fontSize: '12px' }}
            >
              {expandedCommentId === comment._id ? 'Hide Replies' : 'Show Replies'}
            </Button>
              
            {/* Conditionally Render Replies */}
            {expandedCommentId === comment._id && <ReplySection commentId={comment._id} author={comment.author} />}
            
            

             {/* Drawer for Reply Input */}
      <Drawer anchor="bottom" open={drawerOpen3} onClose={handleDrawerToggle3}>
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2, mb: 8 }}>
          <Typography variant="body1">Reply to Comment</Typography>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Write a reply..."
            fullWidth
            sx={{ mb: 2 }}
          />
          <Button variant="contained" onClick={() => console.log("Reply Submitted")}>
            Submit Reply
          </Button>
        </Box>
      </Drawer>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>




<Drawer
      anchor="bottom"
      open={drawerOpen1}
      onClose={handleDrawerToggle1}
      sx={{
        '& .MuiPaper-root': {
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
        }
      }}
    >
<Paper sx={{ my: 1,  mx: 'auto', p: 2, width: '100%' ,mb:4  }}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body1" sx={{ fontWeight: 700, textAlign: 'center' }}>
          Share via
        </Typography>
      </Grid>
      <SocialMediaShareButton platform="facebook" shareUrl={shareUrl} title={title} icon={Facebook} />
      <SocialMediaShareButton platform="twitter" shareUrl={shareUrl} title={title} icon={Twitter} />
      <SocialMediaShareButton platform="whatsapp" shareUrl={shareUrl} title={title} icon={WhatsApp} />
      <SocialMediaShareButton platform="linkedin" shareUrl={shareUrl} title={title} icon={LinkedIn} />
      <SocialMediaShareButton platform="instagram" shareUrl={shareUrl} title={title} icon={Instagram} />
      <SocialMediaShareButton platform="email" shareUrl={shareUrl} title={title} icon={Email} />
    </Grid>
  </Paper>
  </Drawer>

  </Box>
  );
}

export default PCommentsSingle;

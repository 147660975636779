import React, { useRef, useState } from 'react';
import gkorigin from '../vids/nameorigin.mp4';
import Card from '@mui/material/Card';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useTheme } from '@mui/material/styles';

const VideoPlayer = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <Box style={{ display: 'flex', borderRadius: '0px', margin: '0px 0', padding: '0px',
    backgroundimage: `url(${'https://storage.googleapis.com/app_darkendimg/vids/koffiewood.png'})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    objectFit: 'contain',
    
    
    }}>
      <Card sx={{ borderRadius: '15px', backgroundColor: '#fff', overflow: 'hidden', position: 'relative', width: "100%", padding: '0px', boxShadow: 5 }}>
        <video
          style={{  width: '100%', height: '100%', objectFit: 'cover', borderRadius: '0px' }}
          ref={videoRef}
          poster='https://storage.googleapis.com/app_darkendimg/vids/questioncover.png'
          >
            
          <source src='https://storage.googleapis.com/app_darkendimg/vids/The%20Koffie%20Question.mp4' type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
          <IconButton onClick={handlePlayPause} sx={{backgroundColor: 'transparent'}}>
            {isPlaying ? (
              <PauseIcon style={{ color: '#077336', fontSize: '8rem' }} />
            ) : (
              <PlayArrowIcon style={{ color: '#077336', fontSize: '8rem' }} />
            )}
          </IconButton>
        </div>
      </Card>
      
    </Box>
  );
};

export default VideoPlayer;

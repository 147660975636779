import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { CardMedia, Divider, Typography , Grid, Card, CardContent} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { Button, Box } from '@mui/material';
import { Avatar } from '@mui/material';
import { useSpring, animated, config } from 'react-spring';
import StoreAdd from '../adds/storeadd';
import Progress from '../../mods/progress';



const SUB_DATA = gql`
  query GetSubData {
    subs {
      _id
      name
      com1
      com2
      qty
      com3
      com4
      com5
      com6
      com7
      com8
      about
      Title
      imageurl
    }
  }
`;



const ProductDisplay = () => (
  <section>
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Starter Plan
      </Typography>
    </Box>
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    <form action='https://buy.stripe.com/14k3eGfH2eaK7IsaEE' >
          {/* Add a hidden field with the lookup_key of your Price */}
      <input type="hidden" name="lookup_key" value="003" />
      <button id="checkout-and-portal-button" type="submit">
        Checkout
      </button>
    </form>
    </Box>
  </section>
);

const SuccessDisplay = ({ sessionId }) => {
  const handleManageBilling= async () => {
    try {
      const response = await axios.post('/create-portal-session', {
        session_id: sessionId,
      });
      // If successful, redirect the user to the Billing Portal
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Error creating portal session", error);
      // Handle the error appropriately
    }
  };

  return (
    <section>
      <div className="product Box-root">
        
        <div className="description Box-root">
          <h3>Subscription to starter plan successful!</h3>
        </div>
      </div>
      <button id="checkout-and-portal-button" onClick={handleManageBilling}>
        Manage your billing information
      </button>
    </section>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function Sub() {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');
  const { loading, error, data } = useQuery(SUB_DATA);


   // useSpring animation
const animationStyles = useSpring({
  from: { transform: 'translateX(100%)', opacity: 0 },
  to: async (next) => {
    // Slide in from right to left smoothly
    await next({ transform: 'translateX(0%)', opacity: 1, config: { tension: 120, friction: 8 } });
    // Optional: keeps the item visible without disappearing, remove the next line if you want it to disappear after sliding in
    await next({ opacity: 1, config: { duration: 1000 } });
  },
  delay: 600,
});


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');
    if (sessionId) {
      setSessionId(sessionId);
      setSuccess(true);
    }
  }
  , []);


  if (loading) return <p><Progress/></p>;
  if (error) return <p>What Happened to the Subscribptions??</p>;

  return (
    <Box sx={{ width: '100%' }}>
    <Box sx={{ width: '100%', paddingTop: 2, mb: 5,   }}>
    
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', padding: 2, 
  borderRadius: 0, 
  backgroundImage: `url('https://storage.googleapis.com/app_darkendimg/assets/subimage.png')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  boxShadow: 0,
  height: '50vh',
  mb:1,
  
 
  }}>
       <animated.div style={animationStyles}>
       
        
       
        <Typography variant="h5" sx={{ mt:5, color: '#fff', textShadow: '1px 1px 1px #000', fontWeight: 300 }}>
        Subscribe to KoffieLand™
        </Typography>
        <Typography variant='h5' sx={{ color: '#fff', textShadow: '1px 1px 1px #000', fontWeight: 800 }}>
        The Journey Pack™
        </Typography>
        <Typography variant="h6" sx={{ color: '#fff', textShadow: '1px 1px 1px #000', fontWeight: 700 }}>
        Auto Refresh your Bean tokens
        </Typography>
        </animated.div>
        <Typography variant="h6" sx={{ mt: 25, color: '#fff', textShadow: '1px 1px 1px #000', fontWeight: 600, textAlign: 'center' }}>
        3lbs per month 12k Bean tokens
        </Typography>
      </Box>
     
    
    <Box sx={{ borderRadius: 0, mt: 0, padding: '0px', alignContent: 'center', justifyContent: 'center', alignItems: 'center', mb: 0, border: 'solid 0px #fff'   }}>
    <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left', padding: 0, borderRadius: 5, boxShadow: 0,  }}>
      <Typography variant="h5"  gutterBottom sx={{ fontWeight: 700, textAlign: 'left',  padding: 1, mb: 0 }}>
      KoffieLand™ Subscriptions
    </Typography>
    </Box>
    <Grid container spacing={4} sx={{ padding: 2 }}>
      {data && data.subs && data.subs.map((sub) => (
        <Grid item key={sub._id} xs={12} sm={12} md={12}>
          <Card sx={{ height: '100%', borderRadius: 5, boxShadow: 1, }}>
            <CardMedia
              component="img"
              image={sub.imageurl}
              alt="Koffie Beans"
              sx={{ height: '250px', borderRadius: '5px 5px 0 0' }}
            />
            <CardContent sx={{ padding: 3,  }}>
        
            <Typography variant="h5" component="h1" gutterBottom sx={{ fontWeight: 700 }}>
          {sub.Title}
        </Typography>
        <Typography variant="body1" component="p" gutterBottom >
          {sub.name}        </Typography>
        <Divider sx={{ my: 2 }} />
        
        <Typography variant="body1" component="p" gutterBottom sx={{color: 'text.secondary'}}>
          <strong style={{ color: '#352536'}}>Four 8oz Bags:</strong> {sub.about}        </Typography>
        <Typography variant="body1" component="p" gutterBottom  sx={{color: 'text.secondary'}}>
          <strong style={{ color: '#352536'}}>Variety and Quality:</strong> {sub.com3}        </Typography>
        <Typography variant="body1" component="p" gutterBottom >
          <strong>Freshness Guaranteed:</strong> {sub.com4}
        </Typography>
        
        <Typography variant="body1" component="p" gutterBottom sx={{color: 'text.secondary'}}>
          <strong style={{ color: '#352536'}}>Why Choose Koffieland:</strong>
        </Typography>
        <Typography variant="body1" component="p" gutterBottom sx={{color: 'text.secondary'}}>
          <strong style={{ color: '#352536'}}>{sub.qty}:</strong> {sub.com5}
        </Typography>
        <Typography variant="body1" component="p" gutterBottom sx={{color: 'text.secondary'}}>
          <strong style={{ color: '#352536'}}>Ethically Sourced:</strong> {sub.com6}
        </Typography>
        <Typography variant="body1" component="p" gutterBottom sx={{color: 'text.secondary'}}>
          <strong style={{ color: '#352536'}}>Expertly Roasted:</strong> {sub.com7}
        </Typography>
        <Typography variant="body1" component="p" gutterBottom sx={{color: 'text.secondary'}}>
          <strong style={{ color: '#352536'}}>Exclusive Access:</strong> {sub.com8}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 5 }}>
        <Typography variant="h6" component="h6" gutterBottom sx={{ fontWeight: 700, color: '#352536' }}>
                ${sub.com1} per month
              </Typography>
          
        </Box>
      </CardContent>
          
            <Box sx={{ padding: 2, display: 'flex', justifyContent: 'flex-end', boxShadow: 'none', }}>
              <form action={sub.com2} style={{ width: '100%', textAlign: 'center', boxShadow: 'none', padding:0 }}>
                <input type="hidden" name="lookup_key" value="003" style={{boxShadow: 'none'}} />
                <Button
                  id="checkout-and-portal-button"
                  type="submit"
                  sx={{
                    border: 'none',
                    borderRadius: 5,
                    width: '80%',
                    backgroundColor: '#077336',
                    color: 'white',
                    fontWeight: 700,
                    ":hover": { backgroundColor: '#055a26' }
                  }}
                >
                  Subscribe
                </Button>
              </form>
            </Box>
            
              <Box sx={{ padding: 2,  }}>
    <Typography variant="caption" component="p" gutterBottom >
          Your subscription will automatically renew and your payment method on file will automatically be charged the subscription price according to the pre-selected frequency until you cancel. You can cancel at any time on your Koffieland account page or by reaching out to our support team. Please make sure to register for an account once you have completed this order.
        </Typography>
        </Box>
    
          </Card>
        </Grid>
      ))}
    </Grid>

    </Box>
    <StoreAdd />
    
    <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          
          paddingBottom: '0',
         
        }}
      >
<img src="https://storage.googleapis.com/app_darkendimg/profiles/656511973f7cca47d1a5108f/Nice.png" alt="Koffie Beans" style={{ width: '100%', height: 'auto', }} />
</div>
    </Box>
    
    </Box>
    
  );
}






import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Tabs, Tab, Box, Typography, Dialog, DialogContent, Grow, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom'; // React Router hooks for navigation
import { UserContext } from '../../context/usercontext';
import CoffeeIcon from '@mui/icons-material/Coffee';
import Person from '@mui/icons-material/Person';
import AgricultureOutlined from '@mui/icons-material/AgricultureOutlined';
import FactoryOutlined from '@mui/icons-material/FactoryOutlined';
import StoreIcon from '@mui/icons-material/Store';
import Face3OutlinedIcon from '@mui/icons-material/Face3Outlined';
import CurrencyBitcoinOutlinedIcon from '@mui/icons-material/CurrencyBitcoinOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import Store from '../mods/frontgrid';
import KupList from '../profile/users-ui-comp/list-kupster';
import FarmerList from '../profile/users-ui-comp/list-farmer';
import RoasterList from '../profile/users-ui-comp/list-roaster';
import ShopList from '../profile/users-ui-comp/list-shop';
import BaristaList from '../profile/users-ui-comp/list-barista';
import Chron from '../../pages/chron';
import AllKoffie from '../../pages/worldkoffie';
import UserDet from '../../pages/userdeets'; // Assuming this is the component for user details
import UserMarket from '../finance/usermarket';
import Homes from '../mods/homes';
import Apparel from '../mods/apparel';
import Tech from '../mods/tech';
import Health from '../mods/health';
import Sports from '../mods/sports';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import { CottageOutlined, IronOutlined, MonitorHeartOutlined } from '@mui/icons-material';
import LaptopOutlinedIcon from '@mui/icons-material/LaptopOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined';
import MultiFileUploads from '../profile/productui';

function LinkTab(props) {
  return (
    <Tab
      sx={{
        minWidth: 'auto',
        maxWidth: 'none',
        fontSize: '12px',
        padding: '6px 12px',
        bgcolor: 'transparent',
        whiteSpace: 'nowrap',
        color: 'inherit',
        '&.Mui-selected': {
          color: '#077336',
        },
      }}
      {...props}
    />
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

export default function ShopTabs() {
  const { user } = useContext(UserContext);
  const [tabValue, setTabValue] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); // Keep track of the selected user for dialog

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleUserClick = (user) => {
    setSelectedUser(user); // Set the selected user
    setDialogOpen(true); // Open the dialog
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedUser(null); // Clear the selected user when the dialog is closed
  };

  const renderTabContent = () => {
    switch (tabValue) {
      case 0:
        return <UserMarket />;
      case 1:
        return <Homes onUserClick={handleUserClick} />; // Pass the click handler to KupList
      case 2:
        return <Apparel onUserClick={handleUserClick} />; // Pass the click handler to FarmerList
      case 3:
        return <Tech onUserClick={handleUserClick} />;
      case 4:
        return <Health onUserClick={handleUserClick} />;
      case 5:
        return <MultiFileUploads onUserClick={handleUserClick} />;
       
      default:
        return null;
    }
  };

  return (
    <>
      {user && (
        <Box sx={{ maxWidth: '100%', bgcolor: 'background.paper', padding: 0 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
            TabIndicatorProps={{
              style: { backgroundColor: '#077336', color: 'inherit' },
            }}
          >
            <LinkTab  label="User Market" />
            <LinkTab label="Home & Office" />
            <LinkTab  label="Clothes" />
            <LinkTab label="Tech" />
            <LinkTab label="Self Care" />
            <LinkTab  label="Sports" />
            
          </Tabs>

          {/* Tab Content */}
          {renderTabContent()}

          {/* User Details Dialog */}
         
        </Box>
      )}
    </>
  );
}

import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Box, Card, CardContent, CardMedia, Typography, Drawer, Modal, Avatar, useMediaQuery } from '@mui/material';
import { Link , useNavigate} from 'react-router-dom';
import { useContext } from 'react';
import {UserContext} from '../../context/usercontext';
import { useState } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ArrowBack } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import PComments from '../mods/postcomments';
import PCommentsSingle from '../mods/pcommentsSingle';
import { useTheme } from '@mui/material/styles';


const useStyles = makeStyles((theme) => ({
    root: {
       // You can set this to match the width in your UI
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
     
      
    },
    media: {
      
      
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      borderRadius: 10,
      
    },
    avatar: {
      backgroundColor: theme.palette.secondary.main,
    },
    actionArea: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
    },
  }));


const GET_POST_BY_ID = gql`
  query GetPostById($_id: ObjectId!) {
    post(query: {_id: $_id}) {
        _id
        content
        createdAt
        author
        imageurl
        contentType
        userID
    }
  }
`;

const Post = () => {
    const { user } = useContext(UserContext);
    console.log("User:", user); // Add this line to log the user object
    const { _id } = useParams(); // Retrieves 'id' from the URL
    console.log("Retrieved id:", _id); // Add this line to log the id
    const [isDrawerOpen, setIsDrawerOpen] = useState(true); // Add this line to initialize the state variable
    const navigate = useNavigate();
    const [open, setOpen] = useState();
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleDrawerOpen = () => {
        setIsDrawerOpen(true);
    }
    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
        navigate(-1); // This will navigate back to the previous page
    }

    //Open Modal
    const handleOpenModal = () => {
        setOpen(true);
    };


    const { loading, error, data } = useQuery(GET_POST_BY_ID, {
        variables: { _id: _id }, // Passes 'id' as an ObjectId
    });

    const renderMedia = (media, title, poster) => {
      if (media && media.length > 0) {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              marginTop: 2,
              padding: 0,
            }}
          >
            {media.map((item, index) => {
              // Check if the item is a video file based on its extension
              const isVideo = /\.(mp4|webm|mov)$/i.test(item);
    
              return (
                <Box key={index} sx={{ marginBottom: '10px', width: '100%' }}>
                  {isVideo ? (
                    <video controls style={{ borderRadius: 10, width: '100%', padding: 0 }}>
                      <source src={item} type="video/mp4" />
                      Sorry, your browser doesn't support embedded videos.
                    </video>
                  ) : (
                    <CardMedia
                      component="img"
                      image={item} // Use `image` prop for CardMedia
                      alt={`${title} ${index + 1}`}
                      sx={{
                        borderRadius: 2,
                        padding: 0,
                        objectFit: 'contain',
                        width: '100%',
                        height: 'auto',
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Box>
        );
      }
    
      return null; // Return null if there's no media
    };
    
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;



    return (
      <>
      {isMobile &&
      <Drawer
      anchor="bottom"
      open={isDrawerOpen}
      onClose={handleDrawerClose}
      PaperProps={{
        sx: {
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }
      }}
    >
      {/* Header */}
      <Box sx={{
  display: 'flex',
  backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',  // Dark mode: dark background, Light mode: white background
  alignItems: 'center',
  padding: '8px',
  borderBottom: '0px solid #e0e0e0',
  marginTop: '2px',
  position: 'relative'  // Added to position the IconButton absolutely
}}>
  <IconButton 
    onClick={handleDrawerClose}
    sx={{
      position: 'absolute',
      left: 8,
      top: '50%',
      transform: 'translateY(-50%)'
    }}
  >
    <ArrowBack />
  </IconButton>
  <Typography 
    variant="body1" 
    sx={{
      flexGrow: 1,
      textAlign: 'center',
      fontWeight: 700,
     
    
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      display: 'flex',
      alignContent: 'flex-end',


    }}
  >
    {data?.post?.title || 'Post'}
  </Typography>
</Box>
    
      {/* Content */}
      <Box sx={{ 
        flexGrow: 1, 
        overflowY: 'auto', 
        padding: 2,
        backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',  // Dark mode: dark background, Light mode: white background
        mb: 5
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 0 }}>
          <Avatar src={data.post.imageurl} alt="Avatar" />
          <Typography variant="body2" sx={{textDecoration: 'none'}}>
            <Link to={`/userprof/${data.post.userID}`} style={{textDecoration: 'none', fontWeight: 600, color: '#077336' }}>
              {data.post.author}
            </Link>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {new Date(data.post.createdAt).toLocaleString()}
          </Typography>
        </Box>
    
        {data && data.post && (
          <Card key={data.post._id} sx={{  boxShadow: 0 }}>
            <CardContent>
  <Typography variant="body1" color="textPrimary" component="p">
        <Link to={`/posts/${data.post._id}`} key={data.post._id}  style={{ textDecoration: 'none', color: 'inherit' }}>
            {data.post.content}
        </Link>
    </Typography>
  </CardContent>
  {data.post.imageurl && (
        <Box onClick={handleOpenModal} sx={{ display: 'flex', alignItems: 'center', mt: -2, gap: 2 }}>
          {renderMedia([data.post.imageurl], data.post.content, data.post.imageurl)}
        </Box>
      )}


            <Modal
                open={open}
                onClose={handleClose}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                BackdropProps={{
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 1)', // Black with opacity
                    },
                }}
            >
             
                <Box style={{ outline: 'none' }}>
                  
                    <img src={data.post.imageurl} alt="Enlarged Post" style={{ maxWidth: '100%', maxHeight: '100vh' }} />
                </Box>
            </Modal>
            <CardContent>
           
             <Typography variant="h4" color="text.secondary">
               
               <PCommentsSingle post={data.post} />
              </Typography>
              {/* Add any other post details here */}
            </CardContent>
           
          </Card>
        )}
        
      </Box>
      
      {/* Modal for enlarged image */}
      <Modal
        open={open}
        onClose={handleClose}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 1)',
          },
        }}
      >
        <Box style={{ outline: 'none' }}>
          <img src={data.post.imageurl} alt="Enlarged Post" style={{ maxWidth: '100%', maxHeight: '100vh' }} />
        </Box>
      </Modal>
     
    </Drawer>
}
    {!isMobile && 
      <Box

      open={isDrawerOpen}
      onClose={handleDrawerClose}
      PaperProps={{
        sx: {
         
          width: '300px',
          flexDirection: 'column',
        }
      }}
    >
      {/* Header */}
      <Box sx={{
  display: 'flex',
  alignItems: 'center',
  padding: '8px',
  borderBottom: '0px solid #e0e0e0',
  marginTop: '2px',
  position: 'relative'  // Added to position the IconButton absolutely
}}>
  <IconButton 
    onClick={handleDrawerClose}
    sx={{
      position: 'absolute',
      left: 8,
      top: '50%',
      transform: 'translateY(-50%)'
    }}
  >
    <ArrowBack />
  </IconButton>
  <Typography 
    variant="body1" 
    sx={{
      flexGrow: 1,
      textAlign: 'center',
      fontWeight: 700,
      paddingLeft: '40px',  // To offset the space taken by the IconButton
      paddingRight: '40px'  // For symmetry
    }}
  >
    {data?.post?.title || 'Post'}
  </Typography>
</Box>
    
      {/* Content */}
      <Box sx={{ 
        flexGrow: 1, 
        overflowY: 'auto', 
        padding: 2
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
          <Avatar src={data.post.imageurl} alt="Avatar" />
          <Typography variant="body2" sx={{textDecoration: 'none'}}>
            <Link to={`/userprof/${data.post.userID}`} style={{textDecoration: 'none', fontWeight: 600, color: '#077336' }}>
              {data.post.author}
            </Link>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {new Date(data.post.createdAt).toLocaleString()}
          </Typography>
        </Box>
    
        {data && data.post && (
          <Card key={data.post._id}>
            <CardContent>
  <Typography variant="body1" color="textPrimary" component="p">
        <Link to={`/posts/${data.post._id}`} key={data.post._id}  style={{ textDecoration: 'none', color: 'inherit' }}>
            {data.post.content}
        </Link>
    </Typography>
  </CardContent>
  {data.post.imageurl && (
        <Box onClick={handleOpenModal} sx={{ display: 'flex', alignItems: 'center', mt: -2, gap: 2 }}>
          {renderMedia([data.post.imageurl], data.post.content, data.post.imageurl)}
        </Box>
      )}
<PCommentsSingle post={data.post} />

            <Modal
                open={open}
                onClose={handleClose}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                BackdropProps={{
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 1)', // Black with opacity
                    },
                }}
            >
             
                <Box style={{ outline: 'none' }}>
                  
                    <img src={data.post.imageurl} alt="Enlarged Post" style={{ maxWidth: '100%', maxHeight: '100vh' }} />
                </Box>
            </Modal>
            <CardContent>
           
             <Typography variant="h4" color="text.secondary">
               
               
              </Typography>
              {/* Add any other post details here */}
            </CardContent>
           
          </Card>
        )}
        
      </Box>
      
      {/* Modal for enlarged image */}
      <Modal
        open={open}
        onClose={handleClose}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 1)',
          },
        }}
      >
        <Box style={{ outline: 'none' }}>
          <img src={data.post.imageurl} alt="Enlarged Post" style={{ maxWidth: '100%', maxHeight: '100vh' }} />
        </Box>
      </Modal>
     
    </Box>
    }
    </>
        
    );
};

export default Post;


//import '../../styles/styles.css';
import { useQuery,  useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { UserContext } from '../context/usercontext';
import { useUser } from '../queries/krons';
import { Link, useParams, useNavigate } from 'react-router-dom'
import React, { useState, useEffect, useContext } from 'react';
import { Button, CardActionArea, CardActions, Container, Icon, ListItemIcon, Menu, Popover, Typography, styled,  } from '@mui/material';
import { useMutation } from '@apollo/client';
import StoryCard from '../components/mods/comments';
import { Card, TextField } from '@mui/material';
import Progress from '../components/mods/progress';
import Koffieticket from '../components/finance/prods/coffee';
import DongFeng5 from '../components/tests/dongfeng5';
import Aigql from '../components/tests/aigqltalk';
import { useItem } from '../components/finance/mangeshop/useitems';
import { List, ListItem, ListItemText, ListItemAvatar, CardMedia, CardHeader, Grid, Avatar, IconButton , MenuItem, Chip, Fab, CardContent, Drawer, Box, Modal, Dialog, Slide, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DFive from '../components/tests/dongfengfive';
import DFiveAI from '../components/tests/Dfiveai';
import { makeStyles } from '@mui/styles';
import { AddCircle, Clear, MenuBook, Upload } from '@mui/icons-material';
import Flavloader from '../components/mods/flavloader';
import Header from '../components/nav/mobilefarmer';
import logo from '../images/openkoffie1.png';
import Skeleton from '@mui/material/Skeleton';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import { CollaborateButton, CollaboratorsCount, UncollaborateButton } from '../components/profile/settings/CollaborateComponents';
import morekoffie from '../images/morekoffie.png';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import Faq from './faq';
import { InsertPhoto } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import useFileUpload from '../components/profile/cloud';
import { isMobile } from 'web3modal';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSpring, animated, config } from 'react-spring';
import { Grow } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import Divider from '@mui/material/Divider';
import MoreVert from '@mui/icons-material/MoreVert';
import Close from '@mui/icons-material/Close';





const coffeebeans = 'https://storage.googleapis.com/app_darkendimg/assets/brkoin.png';
const slideUpVariants = {
  hidden: { opacity: 0, y: 50 }, // Start position
  visible: { opacity: 1, y: 0 },  // End position
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      ref={ref}
      {...props}
      timeout={{
        enter: 700, // Duration for the grow-in effect
        exit: 500,  // Duration for the shrink-out effect
      }}
      easing={{
        enter: 'cubic-bezier(0.4, 0, 0.2, 1)', // Custom easing function
        exit: 'cubic-bezier(0.4, 0, 0.2, 1)',
      }}
    />
  );
});

// Format Date
function formatDate(date) {
  return new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
}

function formatBeanCount(number) {
  if (number < 1000) {
    return number; // return the same number if less than 1000
  } else if (number < 10000) { // for numbers between 1000 and 9999
    return (number / 1000).toFixed(1) + 'k'; // one decimal place
  } else {
    return Math.round(number / 1000) + 'k'; // round to nearest thousand for numbers 10,000 and above
  }
}

const useStyles = makeStyles(({  theme }) => ({

    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    card: {
      borderRadius: 12,
      minWidth: 256,
      textAlign: 'center',
      transition: '0.3s',
    },
    avatar: {
      width: 50,
      height: 50,
      margin: 'auto',
    },
    heading: {
      fontSize: 18,
      fontWeight: 'bold',
      letterSpacing: '0.5px',
      marginTop: 8,
      marginBottom: 0,
    },
    subheader: {
      fontSize: 14,
      
      marginBottom: '0.875em',
    },
    media: {
      minHeight: 250,
      width: 345,
      
      borderRadius: 10,
      
    },
    statLabel: {
      fontSize: 12,
      color: "none",
      fontWeight: 500,
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      margin: 0,
    },
    statValue: {
      fontSize: 14,
      fontWeight: 'normal',
      marginBottom: 4,
      letterSpacing: '1px',
      fadeIn: 'ease-in 5s',
      visibility: 'visible',
      animation: 'fadeIn ease-in 5s',
      
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center', // Centers the button horizontally
      alignItems: 'center', // Centers the button vertically if needed
      width: '80%',
      height: '40px',
      borderRadius: 20,
      backgroundColor: '#077336', // Green color, you can change it as per your theme
      color: 'white',
      border: 'none',
      marginTop: 30,
      fontSize: 16,
      fontWeight: 400,
    },
  }));

  

const GET_USER = gql`
  query Userprof ($_id: ObjectId!)  {
  userprof (query: {_id: $_id}) {
    
    Nickname
    Title
    _id
    Koffie_story
    usertype
    imageurl
    beanCount
    
  }
}
`; 

const GET_USER_POST = gql`
  query Userpost ($userID: ObjectId!)  {
  posts (query: {userID: $userID}) {
    
    title
    description
    _id
    imageurl
    userID
    
  }
}
`;

//Mutate Koffie
const UPDATE_USERPROF = gql`
  mutation updateOneUserprof($query: UserprofQueryInput, $set: UserprofUpdateInput!) {
    updateOneUserprof(query: $query, set: $set) {
      _id
      name
      description
      country   
      tasteProfile
      purchaseLink
      Koffie_story
      imageurl
    }
  }
`; 

const GET_USER_PROD_QUERY = gql`
  query GetUserGoals($userID: String!) {
  products(query: {userID: $userID}) {
    prompt
    _id
    userID
    name
    price
    
    imageurl
    
  }
}  
`;

const GET_USER_POST_QUERY = gql`
  query GetUserPosts($userID: String!) {
  posts(query: {userID: $userID},sortBy: CREATEDAT_DESC) {

    _id
    userID
    createdAt
    content
    imageurl
    
  }
}
`;

const INSERT_ONE_AIADD = gql`
  mutation insertOneAiadd($data: AiaddInsertInput!) {
    insertOneAiadd(data: $data) {
      _id
      userID
      When
      Title
      description
      price
      productID
      imageurl
     
    }
  }
`;

const kupakoffie = () => {
  return {
    "name": "Barista",
    "description": "Give info based on available data.",
    "parameters": {
      "type": "object",
      "properties": {
        "Title": {
          "type": "string",
          "description": "Title of Subject"
        },
        "price": {
          "type": "string",
          "description": "do not output dollar sign. Integer Only."
        },
        "description": {
          "type": "string",
          "description": "Tell a short tale of the events in what happened."
        },
        "productID": {
          "type": "string",
          "description": "The product ID"
        },

          "primaryImage": 
          {"type": "string",
          "description": "The url of the primary image"
        
        },
        "imageurl":
        {"type": "string",
        "description": "The URL of userimage"
      },
     

      
        "When": 
          {"type": "string",
          "description": "When is this available"
        
        },

        "userID":
        {"type": "string",
        "description": "The user ID"
      }
      
        
      },
      
      "required": ["name", "Title", "description",  "primaryImage", "When",   "price", "productID", "userID", "imageurl"]
    }
  };
};




 // a gql query

 const GET_AI_PROD_QUERY = gql`
 query GetAI($_id: ObjectId!) {
  products(query: { _id: $_id }) {
     _id
     prompt
     name
     price
     imageurl
     userID
   }
 }
`;
const GET_BEAN_COUNT = gql`
  query GetBeanCount($_id: ObjectId!) {
    userprof(query: {_id: $_id}) {
      beanCount
      _id
    }
  }
`;

const DECREMENT_BEAN_COUNT = gql`
  mutation DecrementBeanCount($_id: ObjectId!, $beanCount_inc: Int!) {
    updateOneUserprof(query: { _id: $_id }, set: { beanCount_inc: $beanCount_inc }) {
      _id
      beanCount
    }
  }
`;

const GET_ALL_USERS = gql`
  query GetAllUsers {
    userprofs {
      _id
      Nickname
      Title
      beanCount
      Koffie_story
      usertype
      imageurl
    }
  }
`;

// Define the DELETE_POST mutation with query argument
const DELETE_POST = gql`
  mutation DELETE_POST($query: PostQueryInput!) {
    deleteOnePost(query: $query) {
      _id
      content
    }
  }
`;

const UserCard = ({ user }) => (
  <Grid item xs={3} sm={3} md={3} lg={3} sx={{padding: 0}}>  {/* Adjust grid settings to fit 4 items per row */}
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'background.paper',
        borderRadius: 5,
        boxShadow: 0,
        padding: 1,  // Reduced padding
        p: 1,  // Reduced padding
        border: 0,
      }}
    >
      <Link to={`/${user.usertype}/${user._id}`}>
        <Avatar
          sx={{ border: '1px solid black', width: 60, height: 60 }}  // Smaller avatar
          alt={user.Nickname}
          src={user.imageurl}
        />
      </Link>
      <Typography variant="subtitle2" gutterBottom>
        {user.Nickname}
      </Typography>
      <IconButton size="small" sx={{ padding: '0px' }}>  
        <img src='https://' alt='bean' width={15}/> 
      </IconButton>
      <Typography variant="body2" sx={{ fontWeight: 600 }}>
        {formatBeanCount(user.beanCount)} 
      </Typography>
    </Box>
  </Grid>
);

function DeskUserDet({ userId,  setPostSubmitted  }) {
  
  const { _id } = useParams();
  const _idFromUserParams = _id;
  const { user, fetchUser } = useContext(UserContext);
  const [clickedItems, setClickedItems] = useState([]);
  const [content, setOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false); 
  const [updateUserProf] = useMutation(UPDATE_USERPROF);
  const [descriptionData1, setDescription] = useState(''); // New state for When
  const [secondary, setSecondary] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const classes = useStyles();
  const [beanCount, setBeanCount] = useState(0); // Initialize with 0 beans
  const [showDialog2, setShowDialog2] = useState(false);
  const [showDialog3, setShowDialog3] = useState(false);
  const [insertOneAiadd] = useMutation(INSERT_ONE_AIADD);
  const { file, previewURL, handleFileChange, uploadFile, handleRemoveFile  } = useFileUpload(); // Use your custom hook
  const fileInputRef = React.createRef();
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isChangingImage, setIsChangingImage] = useState(false);
  const [koffieStory, setKoffieStory] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandedCards, setExpandedCards] = React.useState({});
  const [openDialog, setOpenDialog] = useState(true);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const selectedCard = null;
  const [anchorEl, setAnchorEl] = useState(null); // To manage menu open/close
  const [postToDelete, setPostToDelete] = useState(null); // Track the post to delete
  const [deletePost] = useMutation(DELETE_POST, {
    refetchQueries: [{ query: GET_USER_POST_QUERY, variables: { userID: _id } }],
    onCompleted: (data) => {
      console.log('Post deleted successfully:', data);
    },
    onError: (error) => {
      console.error('Failed to delete the post:', error);
    },
  });

  

  // Function to handle opening the MoreVert menu
  const handleClick = (event, postId) => {
    setAnchorEl(event.currentTarget);
    setPostToDelete(postId); // Set the correct postId for deletion when the menu is opened
  };

  // Function to close the MoreVert menu
  const handleCloseVert = () => {
    setAnchorEl(null);
    setPostToDelete(null); // Clear the post to delete when menu is closed
  };

  // Function to handle post deletion
  const handleDeletePost = async () => {
    try {
      await deletePost({
        variables: {
          query: { _id: postToDelete }, // Pass the correct postId to the mutation
        },
      });
      setAnchorEl(null); // Close the menu after deleting
    } catch (error) {
      console.error('Error deleting the post:', error);
    }
  };
 

const [openDialog1, setOpenDialog1] = useState(false);
const [openDialog2, setOpenDialog2] = useState(false);

  useEffect(() => {
    // Delay the appearance of the button to sync with the dialog's transition
    const timer = setTimeout(() => setIsButtonVisible(true), 1000); // Adjusted to 1000ms
    return () => clearTimeout(timer);
  }, []);
  
  const buttonVariants = {
    hidden: { opacity: 0, y: 100 }, // Start the button lower on the Y-axis
    visible: { 
      opacity: 1, 
      y: 0, // Slide up to its final position
      transition: { 
        type: "spring", 
        stiffness: 120, 
        damping: 15, 
        delay: 0.3 // Adjust delay as needed
      }
    },
    hover: { 
      scale: 1.05, // Slightly smaller scale to avoid abruptness
      transition: { duration: 0.3 }
    }
  };
 
 // uspring animation
 const animationStyles = useSpring({
    from: { transform: 'translateY(-100%)', opacity: 0 },
    to: async (next) => {
      // Falls and bounces into place more slowly
      await next({ transform: 'translateY(0%)', opacity: 1, config: { ...config.wobbly, tension: 170, friction: 12 } });
      // Zooms out slowly
      await next({ transform: 'scale(1.2)', opacity: 1, config: { duration: 1000 } });
      // Zooms back in to its original state more slowly
      await next({ transform: 'scale(1)', opacity: 1, config: { duration: 1000 } });
      // Optional: keeps the item visible without disappearing, remove the next line if you want it to disappear after zooming back in
      await next({ opacity: 1, config: { duration: 500 } });
    },
    delay: 600,
  });

  const dialogAnimation = useSpring({
    transform: openDialog ? 'scale(1) translateY(0)' : 'scale(0.8) translateY(-50%)',
    opacity: openDialog ? 1 : 0,
    config: { tension: 300, friction: 20 }, // Customize the animation speed and smoothness
  });


  const handleExpandClick = (id) => {
    setExpandedCards((prev) => ({ ...prev, [id]: !prev[id] }));
    setDialogOpen(false);
  };
  //Handle Image Upload
  const handleImageUpload = () => {
   
    const fileInput = fileInputRef.current;
    fileInput.click();
  }
  const handleChangeScreen = () => {
    setIsChangingImage(true);
  };

  const handleCancelChange = () => {
    setIsChangingImage(false);
  };

  const handlePostAndUpload = async () => {
    if (!file) {
      console.error('No file selected');
      return;
    }
    
  
    setIsUploading(true); // Start uploading
  
    const uploadedImageURL = await uploadFile(file, user.id);
  
    // Check if the upload was unsuccessful
    if (!uploadedImageURL) {
      console.error('File upload failed: no image URL returned');
      setIsUploading(false);
      updateUserProf({
        variables: {
          query: { _id: user.id },
          set: { imageurl: uploadedImageURL },
        },
      });
     
      return;
    }
  
    // Update the imageurl for userprof
    updateUserProf({
      variables: {
        query: { _id: user.id },
        set: { imageurl: uploadedImageURL },
      },
      
    });
    

    setIsChangingImage(false);
    setIsUploading(false);
    
   
  
  }
    

  //Handle showDialog2
  const handleOpen2 = () => {
    setShowDialog2(true);
  }
  const handleClose2 = () => {
    setShowDialog2(false);
  }
  //Handle showDialog3
  const handleOpen3 = () => {
    setShowDialog3(true);
  }
  const handleClose3 = () => {
    setShowDialog3(false);
  }

  //Collaboration and Collaborators
  const handleCollaborate = collaboratorId => {
    // ... logic to collaborate ...
    
};
  const [showDialog1, setShowDialog1] = useState(false);

  const handleOpen1 = () => {
    setShowDialog1(true);
  }
  const handleClose1 = () => {
    setShowDialog1(false);
  }
  
  const navigate = useNavigate();

const handleUncollaborate = collaboratorId => {
    // ... logic to uncollaborate ...
    console.log(collaboratorId);
};

  const [selectedImageUrl, setSelectedImageUrl] = useState(null);

// Modify handleOpen to set the selected image URL
const handleOpen = (imageUrl) => {
  setOpen(true);
  setSelectedImageUrl(imageUrl);
};

// Modify handleClose to reset the selected image URL
const handleClose = () => {
  setOpen(false);
  setSelectedImageUrl(null);
};

  const { data: postData,  } = useQuery(GET_USER_POST_QUERY, {
    variables: { userID: _id , _id: _id },
    fetchPolicy: 'network-only',
    skip: !_id,
  });
  
  
  const isOwner = user && (user.id === _id);

  
    console.log("Is owner:", isOwner);
  
  
 
 
  const [loadDescription, { data: descriptionData }] = useLazyQuery(GET_AI_PROD_QUERY, {
  variables: { // Update based on expected structure of GET_AI_PROD_QUERY
    _id
    
  },
});

    const isCollaborating = 
    user?.collaborators?.some(collaborator => collaborator._id === _id) ;  /* logic to determine if user is already collaborating with _id */
    console.log("Is collaborating:", isCollaborating );

    const { data: beanData, loading: beanLoading, error: beanError } = useQuery(GET_BEAN_COUNT, {
      variables: { _id: _idFromUserParams },
    });
    const [decrementBeanCount] = useMutation(DECREMENT_BEAN_COUNT);
    
  
    useEffect(() => {
      if (beanData && beanData.userprof) {
        setBeanCount(beanData.userprof.beanCount);
      }
    }, [beanData]);
    
  
  // Refetch queries to update the collaborators count after a mutation
  const refetchQueries = [
    { query: CollaboratorsCount, variables: { userId: user._id } },
    { query: CollaboratorsCount, variables: { userId: _id } }
  ];

  const handleShowDescription = (productId) => {
    loadDescription({
     
      variables: { _id: productId },
    });
  };

  useEffect(() => {
    if (descriptionData && descriptionData.product) {
      setDescription(descriptionData);
    }
    
  }, [descriptionData]);


  const styles = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false); // State for controlling the drawer
  const [drawerOpen1, setDrawerOpen1] = useState(false); // State for controlling the drawer



  const handleDrawerToggle1 = () => {
    setDrawerOpen1(true); // Explicitly open the drawer
    console.log("Drawer toggled. New state:", true);
  };

const handleDrawerToggle = () => {
  setDrawerOpen((prev) => !prev);
  console.log("Drawer toggled. New state:", !drawerOpen);
};
  const toBeanbag = () => {
    navigate('/chat');
  }
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
 
  const coffeeFunction = kupakoffie();  // Get the coffee function

  
const { data: ProdData } = useQuery(GET_USER_PROD_QUERY, {
  variables: { userID: _id , _id: _id},
  fetchPolicy: 'cache-and-network',  // Add this line
  skip: !_id
});
const product = ProdData ? ProdData.products : [];
  
const { loading, data, error } = useQuery(GET_USER, {
  variables: { _id: _id },
  skip: !_id
});


  if (!_id) {
    return <div>Error: ID not found in URL</div>;
  }
  
  console.log("Data from query:", data);
  if (loading) return <div><Progress/></div>;
  if (error) return <div>Unexpected Error: {error.message}</div>;

  const clearClickedItems = () => {
    setClickedItems([]);  // Reset clickedItems to an empty array
  };

  // Handle submit story to the userprof
  const handleSubmitStory = () => {
    // Logic to submit a story to the userprof
    updateUserProf({
      variables: {
        query: { _id: _id },
        set: { Koffie_story: koffieStory },
      },
    });
    setIsEditing(false);
    handleClose2();
  }

  // Handle edit story
  const handleEditStory = () => {
    setKoffieStory(data.userprof.Koffie_story || '');
    setIsEditing(true);
  };

   // Handle close modal
   const handleCloseModal = () => {
    setIsEditing(false);
    handleClose2();
  };


  const handleSubmit = (productId) => {
    const prodData = (product.find((product) => product._id === productId));
   
    if (beanCount < 1) {
      setShowDialog(true); // This will automatically show the dialog
      return;
      
    }
    
    setIsLoading(true);  // Start loading before the fetch request
    // Call the lazy query to fetch the product description
    handleShowDescription(productId);
    // Fetch the product description using the lazy query
   
    // Reset the clickedItems array after the fetch request is complete

    const cleanPrice = (price) => {
      return price.replace(/[^0-9]/g, ''); // Remove any non-numeric characters
    };

      const handleUpdate  = (outputData) => {
        insertOneAiadd({
          variables: {
            data: outputData.data
          }
        });
      };

    
    const handleUpdate1 = (outputData) => {
      updateUserProf({
        variables: {
          query: { _id: outputData._id },
          set: outputData.data
        }
      });
    }
    
    fetch('https://api.openai.com/v1/chat/completions', { // replace with your server URL
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${"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"}`,

      },
      body: JSON.stringify({
        
        "messages": [
           
            {"role": "system", "content": "You are the world's best sales agent. With the best sense of humer. based on the Item information, sell it to get Rich. Do not output dollar sign on price, integer only :)" },
            { role: "user", content: `What are the best selling points of this product?  : ${JSON.stringify(prodData)}.` },
            
            ],
            
            "temperature": 0.9,
            'model': 'gpt-4o',
             'functions': [coffeeFunction],
              "function_call": {
                "name": "Barista",
                "arguments": JSON.stringify(data.product),
              },
       
        
        
      }),
    })
    .then(response => response.json())
    .then(data => {
      let contentData;
      if (data.choices && data.choices[0] && data.choices[0].message && data.choices[0].message.content) {
        contentData = {
          When: data.choices[0].message.content.When,
          Title: data.choices[0].message.content.Title,
          price: data.choices[0].message.content.price,
          description: data.choices[0].message.content.description,
          primaryImage: data.choices[0].message.content.primaryImage,
          productID: data.choices[0].message.content.productID,
          userID: data.choices[0].message.content.userID,
          imageurl: data.choices[0].message.content.imageurl,
          
          
        };
        
      } else if (data.choices && data.choices[0] && data.choices[0].message && data.choices[0].message.function_call) {
        contentData = JSON.parse(data.choices[0].message.function_call.arguments);
        contentData.price = cleanPrice(contentData.price); // Clean the price here
      }
      
  
      setOutput(contentData); // Render the content on the frontend
      setShowDialog1(true); 
      console.log('Success:', contentData);
      console.log('Total tokens used:', data.usage.total_tokens);
      console.log('Success:', data);
  
      // Construct the variables for the GraphQL mutation
      const output = {
        data: contentData,
        _id: user.id
        
      };
  
      // Perform the GraphQL mutation using the 'output' object
      handleUpdate(output);
     
      setIsLoading(false); // End loading after receiving the response
    })
    .catch(error => {
      console.error('Error:', error);
      setIsLoading(false); // End loading if there's an error
    });
      
  };

  const handleAddKoffieStory = () => {
    // Logic to add a Koffie story, e.g., open a modal or redirect to another page
  };
  
    
  const NoBeans = ({ open, onClose }) => {
    return (
      <Dialog open={open} onClose={onClose} sx={{ textAlign: 'center', zIndex: 1500 }} >
        
        <DialogTitle><img src={morekoffie} alt='logo' width={50} /></DialogTitle>
        <DialogContent>
              <Typography variant="h6">The Agent needs Koffie to run</Typography>
              <Typography variant="body2">No Beans assigned to this Agent.</Typography>
              <Typography variant="caption">Check Back when user has assigned beans to agent</Typography>
              
          
          
        </DialogContent>
        <DialogActions>
          
          <Chip label="Close" onClick={onClose}  />
        </DialogActions>
      </Dialog>
    );
  };


  const renderMedia = (mediaUrl, title) => {
    if (mediaUrl) {
      const isVideo = mediaUrl.match(/\.(mp4)$/i);
  
      return isVideo ? (
        <video controls width="100%" style={{ borderRadius: 10, padding: 0 }}>
          <source src={mediaUrl} type="video/mp4" />
          Sorry, your browser doesn't support embedded videos.
        </video>
      ) : (
        <CardMedia
          component="img"
          image={mediaUrl}
          alt={title}
          style={{ width: '100%' }}
        />
      );
    }
  
    return null;
  };


    return (
        <div>
          
        
      <div style={{paddingTop: 0, paddingBottom: 5,  alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center'}}>
      


      <Card
      key={data.userprof._id}
      sx={{
        borderRadius: 0,
        margin: 0,
        backgroundImage: isChangingImage ? 'none' : `url(${data.userprof?.imageurl})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: 250,
        width: '100%',
        objectFit: 'cover',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        padding: 0,
        position: 'relative', // Add position relative to the card
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', position: 'absolute', top: 10, left: 5 }}>
        <Close sx={{ color: 'green' }} onClick={() => window.history.back()} />
      </Box>
      <div>
  {isOwner && !isChangingImage && (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        position: 'absolute',
        bottom: 16,
        right: 16,
       
      }}
    >
      {/* Use Chip with Avatar */}
      <Chip
        avatar={<Avatar><img src={data.userprof?.imageurl} alt="User"  width={25}/></Avatar>}
        label="Change Image"
        onClick={handleChangeScreen}
        sx={{
          fontWeight: 600,
          borderRadius: 5,
          cursor: 'pointer',
          backgroundColor: '#ebecf0',
          color: '#000', // Use !important to override default styling
          '&:hover': {
            backgroundColor: 'transparent !important', // Ensure hover is also transparent
          },
        }}
      />
    </Box>
  )}
</div>


      {isChangingImage ? (
        <>
          {previewURL && (
            <Box position="relative" display="inline-block">
              <CardMedia
                className="fade-in"
                component="img"
                alt="Preview"
                image={previewURL}
                sx={{ width: '400px', height: '250px', maxWidth: '100%', borderRadius: 2 }}
              />
              <IconButton
                onClick={handleRemoveFile}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  color: 'grey.900',
                  backgroundColor: 'common.white',
                  '&:hover': {
                    backgroundColor: 'grey.100',
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          <Box>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
           <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, padding: 2 }}>
  <Chip 
    label="Select Image" 
    onClick={() => fileInputRef.current.click()} 
    sx={{ cursor: 'pointer',  }} 
  />
  
  <Chip 
    label="Upload Image" 
    onClick={handlePostAndUpload} 
    sx={{ cursor: 'pointer',  }} 
  />

  <Chip 
    label="Cancel" 
    onClick={handleCancelChange} 
    sx={{ cursor: 'pointer', backgroundColor: '#077336', color: '#fff' }} 
  />
</Box>

          </Box>
          <Typography variant="h5" sx={{ fontWeight: 900,  mt: 2 }}>
            {data.userprof.Nickname}
          </Typography>
        </>
      ) : (
        <div className='grid-item'>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 900,
              color: '#fff',
             
            }}
          >
            {data.userprof.Nickname}
          </Typography>
        </div>
      )}
    </Card>
   
       <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, border: '1px solid #ebecf0', borderRadius: 0, padding: 2, marginTop: 2, marginBottom: 0,  }}>
       <Typography variant="caption" sx={{ fontWeight: 500, color: '#077336', fontStyle: 'oblique'}}>
          Collaborating is under development
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0 }}>
        <CollaboratorsCount userId={_id} />
        {!isOwner && !isCollaborating ? (

<Box 
sx={{  
  display: 'flex', 
   flexDirection: 'row',
  border: '0px solid #077336', 
  justifyContent: 'flex-end',  // Adjust this to align content to the right
  width: '100%', // Ensure the box takes full width of its container
}}
>
<Box sx={{ display: 'flex', flexDirection: 'row', gap: 0 }}>
          <CollaborateButton 
          collaboratorId={user.id} 
          collaboratingId={_idFromUserParams}  
        />
        </Box>
  <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0 }}>
          <UncollaborateButton collaboratorId={userId} /* other props */ />
          </Box>
          
        </Box>
        ) : (
         
          <Box 
  sx={{  
    display: 'flex', 
     
    border: '0px solid #077336', 
    justifyContent: 'flex-end',  // Adjust this to align content to the right
    width: '100%', // Ensure the box takes full width of its container
  }}
>
 
</Box>

        
      )}
</Box>
        <div className='grid-item'>
       
  {isOwner ? (
     <Box 
     sx={{  
       display: 'flex', 
       padding: 1,
       border: '0px solid #d1d1d1', 
       justifyContent: 'flex-end',  // Adjust this to align content to the right
       width: '100%', // Ensure the box takes full width of its container
     }}
   >
    {data.userprof.Koffie_story ? (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="body2">{data.userprof.Koffie_story}
      <IconButton onClick={handleEditStory} sx={{ alignSelf: 'flex-end', color: '#077336' }}>
            <EditIcon fontSize='small' />
          </IconButton>
      </Typography>
      </Box>
      
    ) : (
      <Button
        variant="contained" 
        sx={{
          fontWeight: 300,
          backgroundColor: '#077336',
          color: '#fff',
          cursor: 'pointer',
          border: '0px solid #077336',
          borderRadius: 5,
          
          alignSelf: 'flex-end',
          fontStyle: 'italic',
          ':hover': {
            backgroundColor: '#3ff107',
            color: '#000',
            
          },
          
        }}
  
        onClick={handleOpen2 }
      >
        Share your Koffie Story
        </Button>
    )}
    
  </Box>
  
  ) : (
  <Typography variant="body2">{data.userprof.Koffie_story}</Typography>
  
  )}
      
        
        </div>
        
        
      </Box>
      <Modal
         open={showDialog2 || isEditing}
         onClose={handleCloseModal}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={{
            outline: 'none',
            width: '400px',
           
            padding: 2,
            borderRadius: 2,
            boxShadow: 24,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', gap: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: 800, marginBottom: 2 }}>
            Everybody has a Story
          </Typography>
          <IconButton
            onClick={handleCloseModal}
            sx={{ alignSelf: 'flex-end', mt: 0, mb: 2, ml: 'auto' }}
          >
            <CloseIcon />
          </IconButton>
          </Box>
          <TextField
            label="Your Koffie Story" value={koffieStory}
            onChange={(e) => setKoffieStory(e.target.value)}
            multiline
            rows={5}
            variant="outlined"
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <Button
            variant="contained"
            onClick={handleSubmitStory}
            sx={{
              backgroundColor: '#077336',
              color: 'white',
              borderRadius: 2,
              ':hover': {
                backgroundColor: '#06562c',
              },
            }}
          >
            Submit
          </Button>
        </Box>
      </Modal>
      <Koffieticket/>
      
     
        <Drawer
        open={drawerOpen1}
        onClose={() => setDrawerOpen1(false)}
        anchor="right"
        sx={{ zIndex: 1701 }}  
      >
        <Box sx={{ display: 'flex', padding: 2, bgcolor: 'transparent', borderRadius: 5 }}>
          <IconButton onClick={() => setDrawerOpen1(false)} sx={{ alignSelf: 'flex-end' }}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ fontWeight: 600, color: '#077336' }}>
            {data.userprof.Nickname}'s Store
          </Typography>
          </Box>
          <div>
      {product && product.length > 0 ? (
        <div style={{ display: 'block', overflowY: 'auto', textDecoration: 'none', scrollbarWidth: 'none' }}>
          {product.map((item, index) => (
            <Card sx={{ display: 'flex', marginBottom: 2,  borderRadius: 0 }} key={index}>
              
              {/* Image on the Left */}
              <CardMedia
                component="img"
                sx={{ width: 151 ,}}
                image={item.imageurl}
                alt={item.name}
              />

              {/* Text on the Right */}
              <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography component="div" variant="h6">
                    {item.name}
                  </Typography>
                  <Typography variant="h6" component="div" sx={{ color: 'text.secondary', fontWeight: 600 }}>
                    ${item.price}
                  </Typography>
                </CardContent>

                {/* "Generate" Button */}
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                  <Chip
                    label="Generate"
                    onClick={() => handleSubmit(item._id)}
                    avatar={<Avatar src="https://storage.googleapis.com/app_darkendimg/assets/royal.png" alt="Bean" />}
                    variant="outlined"
                    sx={{ border: 'solid 1px', fontWeight: 600 }}
                  />
                </Box>
              </Box>

            </Card>
          ))}
        </div>
      ) : (
        <Typography variant="h6" color="text.secondary">
          No products available
        </Typography>
      )}
    </div>
    <div>
      {showDialog && <NoBeans open={showDialog} onClose={() => setShowDialog(false)} />}   
      {isLoading ? (
      <Dialog open={isLoading} sx={{zIndex: 1500}}>
      <DialogContent>
        <DialogContentText>
          Brewing up your profile...
        </DialogContentText>
      </DialogContent>
      
    </Dialog>
      ) : (
        <>
          {!isMobile ? (
            <>
              <div>
                {showDialog1 && (
                  <Dialog
                    
                    open={showDialog1}
                    onClose={handleClose1}
                    TransitionComponent={Transition}
                    sx={{ zIndex: 1900 }} // Ensure it's on top
                  >    
                    <div>
                      <IconButton onClick={handleClose1}>
                        <Clear />
                      </IconButton>
                      <Typography variant="h6"></Typography>
                    </div>
                    {content && (
                      <Box sx={{ display: 'flex' }}>
                        <Card sx={{ width: '100%', height: '100%', borderRadius: 5, padding: 1 }}>
                          <CardMedia
                            component="img"
                            sx={{ width: '100%', height: 250, borderRadius: 5, objectFit: 'cover'}}
                            image={content.primaryImage}
                            alt="Image description"
                          />        
                          <CardContent sx={{ flexGrow: 1, boxShadow: 0, borderRadius: 5 }}>
                            <Avatar src={data.userprof.imageurl} alt={content.name} />
                            <Typography variant="h5" sx={{fontWeight: 600, }}>
                              {data.userprof.Nickname}'s 
                            </Typography>       
                            <Typography variant='h5'>{content.Who}</Typography>
                            <Typography variant="h6" color="text.secondary" sx={{fontWeight: 600, }}>
                              {content.Title}
                            </Typography>
                            <Typography variant="h6" sx={{
                              fontWeight: 300,
                              color: 'text.secondary',
                              textAlign: 'left',
                              padding: 2
                            }}>
                              {content.description}
                            </Typography>
                            <Typography variant='h6'>{content.When}</Typography>
                            <Typography className={styles.statValue}>{content.purchaseLink}</Typography>
                            <CardActions sx={{ justifyContent: 'flex-end'}}>
                              <Typography variant='h6' sx={{fontWeight: 600, paddingRight: 2}}>${content.price}</Typography> 
                              <Link to={`/product/${content.productID}`}>
                                <Chip label="Buy Now" color='success'/>
                              </Link>    
                            </CardActions>
                          </CardContent>
                        </Card>
                      </Box>
                    )}
                  </Dialog>
                )}
              </div>  
            </>
          ) : (
            <div>
              {showDialog1 && (
                <Dialog
                  open={showDialog1}
                  onClose={handleClose1}
                  TransitionComponent={Transition}
                  sx={{ zIndex: 1300 }} // Ensure it's on top
                >    
                  <div>
                    <IconButton onClick={handleClose1}>
                      <Clear />
                    </IconButton>
                    <Typography variant="h6"></Typography>
                  </div>
                  {content && (
                    <Box sx={{ display: 'flex' }}>
                      <Card sx={{ width: '100%', height: '100%', borderRadius: 5, padding: 1 }}>
                        <CardMedia
                          component="img"
                          sx={{ width: '100%', height: 250, borderRadius: 5, objectFit: 'cover'}}
                          image={content.primaryImage}
                          alt="Image description"
                        />        
                        <CardContent sx={{ flexGrow: 1, boxShadow: 0, borderRadius: 5 }}>
                          <Avatar src={data.userprof.imageurl} alt={content.name} />
                          <Typography variant="h5" sx={{fontWeight: 600, }}>
                            {data.userprof.Nickname}'s 
                          </Typography>       
                          <Typography variant='h5'>{content.Who}</Typography>
                          <Typography variant="h6" color="text.secondary" sx={{fontWeight: 600, }}>
                            {content.Title}
                          </Typography>
                          <Typography variant="h6" sx={{
                            fontWeight: 300,
                            color: 'text.secondary',
                            textAlign: 'left',
                            padding: 2
                          }}>
                            {content.description}
                          </Typography>
                          <Typography variant='h6'>{content.When}</Typography>
                          <Typography className={styles.statValue}>{content.purchaseLink}</Typography>
                          <CardActions sx={{ justifyContent: 'flex-end'}}>
                            <Typography variant='h6' sx={{fontWeight: 600, paddingRight: 2}}>${content.price}</Typography> 
                            <Link to={`/product/${content.productID}`}>
                              <Chip label="Buy Now" color='success'/>
                            </Link>    
                          </CardActions>
                        </CardContent>
                      </Card>
                    </Box>
                  )}
                </Dialog>
              )}
            </div>  
          )}
        </>
      )}            
    </div>  
    </Drawer>
    <div style={{marginBottom: 100}}>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}

      {!loading && !error && postData && postData.posts && postData.posts.length > 0 ? (
        postData.posts.map((post) => (
          <Card key={post._id} sx={{ maxWidth: 400, minHeight: 250, m: 2 , }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 1 }}>
            <CardHeader
              avatar={
                <Avatar
                  src={data.userprof.imageurl}
                  alt={data.userprof.Nickname}
                />
              }
              title={data.userprof.Nickname}
              subheader={formatDate(post.createdAt)}
            />
          {/* list menu icon for post Owner */}
          <>
      {isOwner ? (
        <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={(e) => handleClick(e, post._id)} // Ensure post._id is passed correctly
      >
          <MoreVert /> {/* You can add any icon you want here */}
        </IconButton>
      ) : null}

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)} // Only open when anchorEl is set
        onClose={handleCloseVert} // Close the menu when needed
        PaperProps={{
          style: {
            width: '20ch',
          },
        }}
      >
        <MenuItem onClick={handleCloseVert}>Edit</MenuItem>
        <MenuItem onClick={handleDeletePost}>Delete</MenuItem> {/* Delete the correct post */}
      </Menu>
    </>
            </Box>
            <CardContent>
              <Typography variant="body2">
                {post.content}
              </Typography>
            </CardContent>
            {/* Render image or video */}
            <Box onClick={() => handleOpen(post.imageurl)} sx={{}}>
              {renderMedia(post.imageurl, 'Post Media')}
            </Box>
            <Modal
              open={open}
              onClose={handleClose}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              BackdropProps={{
                style: {
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                },
              }}
            >
              <Box style={{ outline: 'none' }}>
                <img src={selectedImageUrl} alt="Enlarged Post" style={{ maxWidth: '100%', maxHeight: '100vh' }} />
              </Box>
            </Modal>
          </Card>
        ))
      ) : (
        !loading && !error && (
          isOwner ? (
            <Box sx={{ p: 2, textAlign: 'center', border: '1px dashed grey', bgcolor: 'transparent', padding: 1, borderRadius: 5, paddingBottom: 5 }}>
              <Typography variant="h6" fontWeight={200}>You haven't posted anything yet!</Typography>
              <List>
                <ListItem>
                  <Typography variant="body2">Earn 1 bean reward when users like your post.</Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body2">Earn 3 bean rewards when users comment.</Typography>
                </ListItem>
              </List>
              <Typography variant="body1">Redeem beans for in-app prizes or your favorite drinks at participating shops.</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mb: 2 }}>
                <button className={classes.buttonContainer} onClick={() => navigate('/post')}>
                  Create Post
                </button>
              </Box>
            </Box>
          ) : (
            <Box sx={{ p: 3, textAlign: 'center', border: '1px dashed grey', m: 2 }}>
              <Typography variant="h6">User has not posted yet.</Typography>
              <Typography variant="body1">Thanks for visiting, check back later!</Typography>
            </Box>
          )
        )
      )}
    </div>
      {descriptionData && (
        <div>
          {/* Render the fetched description */}
          {descriptionData.product}
        </div>
      )}  
      </div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 0 }}>
        <AnimatePresence>
  {isButtonVisible && (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={buttonVariants}
      style={{ 
        position: 'fixed',
        bottom: 0, 
        right: 16, 
        left: 16, // Add left to stretch to the right
        zIndex: 1300 
      }}
    >
      <Divider sx={{ width: '100vw', position: 'relative', left: '50%', right: '50%', transform: 'translateX(-50%)' }} />
      <DialogActions
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 1,
        width: '100%',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
        <Box sx={{ padding: 0, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
          <Typography variant="body1" sx={{padding: 1, fontWeight: 700}}> {data.userprof.Nickname}'s Smart Shop</Typography>
          <Typography variant="body2" sx={{padding: 1, marginTop: 0 , color: '#077336' }}>Browse Items</Typography>
        </Box>
        <motion.button
          whileHover="hover"
          variants={buttonVariants}
          style={{
            backgroundColor: '#077336',
            borderRadius: '8px',
            color: 'white',
            border: 'none',
            padding: '10px 20px',
            cursor: 'pointer'
          }}
          onClick={handleDrawerToggle1}
        >
          Shop
        </motion.button>
      </DialogActions>
    </motion.div>
  )}
</AnimatePresence>

</Box>
     
      </div>
    
     
      
    )
}

export default DeskUserDet







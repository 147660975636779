import React from 'react';
import ReactPlayer from 'react-player';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography, Box } from '@mui/material';

function AudioPlayerComponent() {
  const [playing, setPlaying] = React.useState(false);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  return (
    <div>
       <Box sx={{ maxWidth: 345,  }}>
      <Card>
        <Box sx={{  }}>
          <CardContent>
            <Typography variant="h5" sx={{ fontWeight: 500 }}>
              The Daily Grind
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              Relevant Media for your Daily Grind
            </Typography>

            <ReactPlayer
              url="https://storage.googleapis.com/app_darkendimg/daily-grind/Play.ht%20-%20_Welcome%2C%20fellow%20Bean%20Landiers%2C%20to%20the....wav"
              playing={playing}
              controls={true}
              width="100%"
              height="50px"
              style={{ display: 'flex', backgroundColor: '', padding: 2 }}
            />
          </CardContent>
        </Box>
      </Card>
    </Box>
    </div>
  );
}

export default AudioPlayerComponent;

import React from 'react'
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {Card, Typography, CardContent, Drawer, Chip, Avatar, CardActions, CardActionArea, Box, IconButton, Grid, Fab, Dialog, CardMedia,
DialogActions,DialogContent, DialogContentText, DialogTitle,
}from '@mui/material';
//import Ticker from '../components/finance/prods/Ticker';
import Koffieticket from '../components/finance/prods/coffee';
import FrontGrid from '../components/mods/frontgrid';
import MainSt from './mainstreet';
import styled from "styled-components";
import WalkThroughGuide from '../components/mods/snacks/guide';
import { useQuery, gql  } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useRef } from 'react';
import { UserContext } from '../context/usercontext';
import { useContext, useState } from 'react';
import FactGen from '../components/mods/factgen';
//import DailyPost from '../components/posts/dailypost';
import FarmerCard from '../components/nav/farmslider';

import LatestPost from '../components/posts/latest';
import AiGen2 from '../components/finance/adds/aifeed2';
import TopBean from '../components/mods/topbean';
import { useNavigate } from 'react-router-dom';
import ContractDetails from '../components/profile/contractdetails';
import { useSpring, animated, config } from 'react-spring';
import Beanomics from '../components/finance/beanomics';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import NavigationIcon from '@mui/icons-material/Navigation';
import Frappuccino from '../components/mods/frapcs';
import StoreAdd from '../components/finance/adds/storeadd';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Value from '../components/finance/adds/valueadd';
import PriceTicket from '../components/finance/prods/pricefeed';
import { Chat } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import StoreEquip from '../components/finance/adds/k-equip';
import StackableCards from '../components/mods/displays/landstack';
import KoffieCalc from '../components/finance/koffiecalc';
import RedeemCoffee from '../components/finance/redeemcoffee';
import BlackIvy from '../components/finance/prods/blackivy';
import Logic from '../components/tests/logic';
import KoffieAI from '../components/profile/workingai';
import ProdMan from './prodman';
import FarmDash from './farmdash';
import FarmerProfile from '../components/tests/farmv';
import Barista from '../components/tests/barista';
import RewardsBanner from '../components/finance/rewards';
import Header from '../components/nav/mobilefarmer';
import Featured from '../components/finance/adds/featured';
import Progress from '../components/mods/progress';
import GoalCard from '../components/finance/adds/goalcard';
import Chron from './chron';
import KoffieStory from './kstory';
import Dhome from './homedesk';




const ScrollContainer = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: center;
  background: transparent;
  
  overflow: hidden;
`;

const Scroll = styled.div`
  overscroll-behavior-x: contain;
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  overflow: auto;
  white-space: no-wrap;
  scroll-behavior: smooth;
  scrollbar-width: none;
  background: transparent;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Other = styled.div`
  display: flex;
  
  
  background: transparent;
  
  border: white solid 0px;
  
  
`;

const Buttoned = styled.button`

background: transparent;
padding: 5px;
border:  none 5px;


`;



const useStyles = makeStyles(() => ({
    root: {
      maxWidth: 450,
      height: 200,
      padding: 5,
      margin: 10,
      borderRadius: 10,
        // This is an example of a light top shadow style
        transition: '0.3s',
       
    },
    content: {
      padding: 5,
      spacing: 8,
    },
    brandCardHeader: {
      // Add styles for BrandCardHeader here
    },
    textInfoContent: {
      // Add styles for TextInfoContent here
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center', // Centers the button horizontally
      alignItems: 'center', // Centers the button vertically if needed
      width: '80%',
      height: '40px',
      borderRadius: 20,
      backgroundColor: '#077336', // Green color, you can change it as per your theme
      color: 'white',
      border: 'none',
      marginTop: 30,
      fontSize: 20,
      fontWeight: 700,
    },
  }));

  const GET_PRODUCTS = gql`
    query Products {
  products {
    Title
    _id
    description1
    description2  
    imageurl
    name
    price
  }
}
`;
  
function Land({  }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState();
  const videoRef = useRef(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDrawerOpen1, setIsDrawerOpen1] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);  
  const [rewardsDrawerOpen, setRewardsDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const [props, set] = useSpring(() => ({
    transform: 'scale(1)',
   
  }));
  
  const animationStyles = useSpring({
    from: { transform: 'translateY(-100%)', opacity: 0 },
    to: async (next) => {
      // Falls and bounces into place more slowly
      await next({ transform: 'translateY(0%)', opacity: 1, config: { ...config.wobbly, tension: 170, friction: 12 } });
      // Zooms out slowly
      await next({ transform: 'scale(1.2)', opacity: 1, config: { duration: 1000 } });
      // Zooms back in to its original state more slowly
      await next({ transform: 'scale(1)', opacity: 1, config: { duration: 1000 } });
      // Optional: keeps the item visible without disappearing, remove the next line if you want it to disappear after zooming back in
      await next({ opacity: 1, config: { duration: 500 } });
    },
    delay: 600,
  });
  

  const handleOpenDialog = (item) => {
    setSelectedItem(item);
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleDrawerOpen1 = () => {
    setIsDrawerOpen1(true);
  };
   const handleDrawerClose1 = () => {
    setIsDrawerOpen1(false);
  };
  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

const handleDrawerOpen = () => {
  setIsDrawerOpen(true);
};

const handleDrawerClose = () => {
  setIsDrawerOpen(false);
};

const smartSuite = [
  { label: 'Smart Calculator', value: 'Smart Calculator', icon: <Chat /> },
  { label: 'Smart Koffie', value: 'Smart Koffie', icon: <Chat /> },
  { label: 'Smart Farmer', value: 'Smart Farmer', icon: <Chat /> },
  { label: 'Conossuier', value: 'Conossuier', icon: <Chat /> },
  { label: 'Smart Equip', value: 'Smart Equip', icon: <Chat /> },
  { label: 'Smart Market', value: 'Smart Market', icon: <Chat /> },
  { label: 'Smart Trade', value: 'Smart Trade', icon: <Chat /> },
  { label: 'Smart Contract', value: 'Smart Contract', icon: <Chat /> },
  { label: 'Smart Coffee', value: 'Smart Coffee', icon: <Chat /> },
  { label: 'Smart Bean', value: 'Smart Bean', icon: <Chat /> },
  { label: 'Smart Roast', value: 'Smart Roast', icon: <Chat /> },
  { label: 'Smart Brew', value: 'Smart Brew', icon: <Chat /> },
  { label: 'Smart Shop', value: 'Smart Shop', icon: <Chat /> },
  { label: 'Smart Home', value: 'Smart Home', icon: <Chat /> },
  { label: 'Smart Barista', value: 'Smart Barista', icon: <Chat /> },
  
];

const DynamicComponentForCategory = ({ item }) => {
  switch (item.value) {
    case 'Smart Calculator':
      return <KoffieCalc />;
    case 'Smart Koffie':
      return <ContractDetails />;
    // Add more cases as per your categories
    case 'Smart Farmer':
      return <FarmDash/>; 
    case 'Smart Equip':
      return <FarmerProfile />;
    case 'Smart Market':
      return <Logic />;
    case 'Smart Trade':
      return <TopBean />;
    case 'Smart Contract':
      return <ContractDetails />;
    case 'Smart Coffee':
      return <Koffieticket />;
    case 'Smart Bean':
      return <Beanomics />;
    case 'Smart Roast':
      return <AiGen2 />;
    case 'Smart Brew':
      return <Frappuccino />;
    case 'Smart Shop':
      return <StoreAdd />;
    case 'Smart Home':
      return <RedeemCoffee />;
    case 'Smart Baritsa':
      return <BlackIvy />;
      case 'Conossuier':
      return <KoffieAI />;
    default:
      return <Logic/>;
  }
};


const renderDrawer = () => (
  <Drawer
    anchor="right" // You can change the anchor if needed
    open={isDrawerOpen}
    onClose={handleDrawerClose}
  >
   
    
    {/* Content of the drawer goes here */}
    <Box sx={{  padding: 2 }}>
     
        
        
    <IconButton onClick={handleDrawerClose}>
      <CloseIcon />
    </IconButton>
    <Chron/>
    
    
      </Box>
      <Box sx={{ flexDirection: 'row',  justifyContent: 'center', mt: 2 }}>
      <Card sx={{ maxWidth: 345,  }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Tryanny
        </Typography>
        
        </CardContent>
        <Typography variant="h4" sx={{
    fontWeight: 300,
    color: 'text.secondary',
    textAlign: 'right', // AligExploren text to the right
    paddingRight: 2,
    paddingTop: 1,
        }}>
          At a time like this, scorching irony, not convincing argument, is needed.
        </Typography>
          
        <CardActions>
         
          <Button sx={{ backgroundColor: '#077336', borderRadius: 10 }} >Learn More</Button>
        </CardActions>
        </Card>
      </Box>
  </Drawer>
);

  const { user, loading: userLoading  } = useContext(UserContext);



 
  
  const { loading, error, data } = useQuery(GET_PRODUCTS);
  
  if (userLoading) {





    return <p><Progress/></p>;
  }
  
  if (!user || !user.accessToken) {
    return <p>User not authenticated.</p>;
  }
  
  
  if (loading) return <p><Progress/></p>;
  if (error) return <p>Error : </p>;

  console.log(data);



  if (loading) return <p><Progress/></p>;
  if (error) return <p>Error: {error.message}</p>;


 
    
  return (
    <Box sx={{ flexGrow: 1 , mt: 2, paddingBottom: 5 }}>
      
      
      
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 3 }}>
        <StackableCards/>
        </div>

       <TopBean/>
       <Header/>
        <KoffieStory/>
       
       <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${'https://storage.googleapis.com/app_darkendimg/vids/koffielandintros.gif'})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100%', // Full viewport height for full-screen effect
        width: '100%', // Full width
        padding: '0',
        margin: '0',
        overflow: 'hidden', // Prevents scrolling
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 4, // Adjust margins for spacing
          mb: 4,
          height: '100%',
          width: '100%',
        }}
      >
        <Box sx={{mt: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
         
          </Box>

      </Box>
    </div>

<StoreAdd/>
    

       <Featured/>
       
            
          
                       
            <RewardsBanner/>
            <Box sx={{ width: '100%', paddingTop: 0 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          
          paddingBottom: '0',
          
        }}
      >
        <Card
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%', // Adjust the calculation based on actual body padding
            backgroundImage: `url(${'https://storage.googleapis.com/app_darkendimg/vids/koffiewood.png'})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            objectFit: 'contain',
            borderRadius: 0,
            boxShadow: 3,
            height: '500px',
            margin: 0,
            overflow: 'hidden', // Ensure no scrollbars appear due to negative margins
          }}
        >
          <Button
            variant='outlined'
            onClick={() => navigate('/us')}
            sx={{
              width: 200,
              mt: 10,
              color: 'black',
              border: 'none',
              borderRadius: 10,
              backgroundColor: '#ffc107',
              '&:hover': {
                backgroundColor: '#3ff107',
                color: '#000',
              },

            }}
          >
            Behind the Scenes
          </Button>
        </Card>
      </div>
    </Box>
    <FactGen/> 
            <AiGen2/>
           
   
            <Card sx={{ borderRadius: 2, backgroundColor: '#333', mt: 4, boxShadow: 3 }}>
      <CardContent sx={{ maxWidth: 600, padding: 4 }}>
        <Typography variant="h4" component="div" sx={{ fontWeight: 700,  mb: 2 }}>
          The Journey Continues
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 400, color: '#ccc', mb: 3 }}>
          Wherever it's introduced, it has spelled revolution. It has been the world's most radical drink in that its function has always been to make people think. And when people begin to think, they become dangerous to tyrants.
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 300, color: '#aaa', textAlign: 'right', pr: 2, pt: 1, pb: 1, fontStyle: 'italic' }}>
          - William Ukers
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Chip 
            label="Learn More" 
            onClick={handleDrawerOpen} 
             sx={{ backgroundColor: '#077336', borderRadius: 10 , color: '#fff'}}
          />
        </Box>
      </CardContent>
    </Card>
          {renderDrawer()}
    <Dialog fullScreen open={openDialog} onClose={() => { setOpenDialog(false); setSelectedItem(null); }}>
 
  <DialogContent sx={{padding: 0}}>
    
    {/* You can also dynamically render different components here based on `selectedItem` */}
    {selectedItem && <DynamicComponentForCategory item={selectedItem} />}
  </DialogContent>
  <DialogActions sx={{ justifyContent: 'center', background: 'transparent', padding: 1}}>
    <button className={classes.buttonContainer} onClick={() => { setOpenDialog(false); setSelectedItem(null); }}>Close</button>
  </DialogActions>
</Dialog>


        
        <Dialog fullScreen open={isDrawerOpen1} onClose={handleDrawerClose1} sx={{padding: 0}}>
          <IconButton onClick={handleDrawerClose1}>
            <CloseIcon />
          </IconButton>
          <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', }}>  
        
       
        </div>
       
        </Dialog>
        
        
            
          {isMobile && <LatestPost/>}
         
          <StoreEquip/>
                      
            
           
         
        <Typography variant="h6" sx={{ mt: 1,  fontWeight: 800, textAlign: 'left', textShadow: '2px 2px 8px rgba(0, 0, 0, 0.2)', }}>
          Smart  Suite
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  }}>
          
        <ScrollContainer >   
        <Scroll>  
          <Other  >
          
     
        

      
      </Other>
      </Scroll>
      </ScrollContainer>
      </div>    
       
      
          

   
        </Box>    
  )
}

export default Land
import React from 'react';
import styled from "styled-components";
import { Avatar, Typography, IconButton, Box } from '@mui/material';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const GET_USERS_BY_TYPE = gql`
  query GetUsersByType($userType: String!) {
    userprofs(query: { usertype: $userType }) {
      _id
      Nickname
      Title
      Koffie_story
      usertype
      imageurl
    }
  }
`;

const ScrollContainer = styled(Box)`
  display: flex;
  align-items: center;
  
  margin-top: 15px;
  border-radius: 0px;
  border: 1px solid #d1d1d1;
  position: relative;
  padding: 10px 20px;
`;

const Scroll = styled(Box)`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Other = styled(Box)`
  display: flex;
  
`;

const Header = ({ userType = "Farmer" }) => {
  const { loading, error, data } = useQuery(GET_USERS_BY_TYPE, {
    variables: { userType },
  });

  const scrollRef = React.useRef(null);

  const scroll = (direction) => {
    if (scrollRef.current) {
      const { current } = scrollRef;
      const scrollAmount = direction === 'left' ? -200 : 200;
      current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <ScrollContainer>
        <IconButton onClick={() => scroll('left')}>
          <ArrowBackIosIcon />
        </IconButton>
        <Box flex="1" overflow="hidden">
          <Typography variant="h6" component="div" sx={{ fontWeight: 800 , alignSelf: 'flex-start'}}>
            Farmers
          </Typography>
          <Scroll ref={scrollRef}>
            <Other>
              {data.userprofs.map((userprofs) => (
                <Box key={userprofs._id} sx={{ padding: '5px', textAlign: 'center' }}>
                  <Link to={`/Farmer/${userprofs._id}`} style={{ textDecoration: 'none' }}>
                    <Avatar
                      sx={{ width: 60, height: 60, margin: 'auto' }}
                      alt={userprofs.Nickname}
                      src={userprofs.imageurl}
                    />
                    <Typography variant="body2" sx={{ marginTop: '5px', fontWeight: 500, color: '#ebecf0' }}>
                      {userprofs.Nickname}
                    </Typography>
                  </Link>
                </Box>
              ))}
            </Other>
          </Scroll>
        </Box>
        <IconButton onClick={() => scroll('right')}>
          <ArrowForwardIosIcon />
        </IconButton>
      </ScrollContainer>
    </>
  );
};

export default Header;

import { UserContext } from "../../context/usercontext";
import { Button, TextField, Box, Typography } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GoogleOneTap from "../mods/googauth";


const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { user, fetchUser, emailPasswordLogin, setUser } = useContext(UserContext);

    const [form, setForm] = useState({
      email: "",
      password: "",
      userType: ""
    });
  
    const onFormInputChange = (event) => {
      const { name, value } = event.target;
      setForm({ ...form, [name]: value });
    };
  
    const loadUser = async () => {
      if (!user) {
        const fetchedUser = await fetchUser();
        if (fetchedUser) {
          redirectNow();
        }
      }
    }
  
    useEffect(() => {
      loadUser(); 
    }, []);
  
    const redirectNow = () => {
      const redirectTo = location.search.replace("?redirectTo=", "");
      navigate(redirectTo ? redirectTo : "/post");
    }
  
    const onSubmit = async (event) => {
      try {
        const user = await emailPasswordLogin(form.email, form.password);
        if (user) {
          const customUserData = await user.refreshCustomData();
          console.log(customUserData);
          setUser({ ...user, Nickname: customUserData.Nickname }); // Assuming that the username is stored under "username" in custom user data.
          
          const fetchedUser = await fetchUser();  // Fetch user after successful authentication
          navigate("/post", { state: { user: fetchedUser }});  // Pass fetched user to Logic via state
        }
      } catch (error) {
        alert('Oops, Invalid Creds. Please try again...');
      }
    };
    

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh',  }}>
        
      <form style={{ display: "flex", flexDirection: "column", maxWidth: "100%", margin: "auto", boxShadow: 'none' }}>
      <Box sx={{  display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: 'auto' }}>
          <img src="https://storage.googleapis.com/app_darkendimg/assets/kland.png" alt="Koffieland Logo" style={{ width: 100, height: 100 }} />
          <Typography variant="h3" sx={{ color: '#077336', fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '1rem' }}>
            Koffieland
          </Typography>
        </Box>
        
        <TextField
          label="Email"
          type="email"
          variant="outlined"
          name="email"
          size="small"
          value={form.email}
          onChange={onFormInputChange}
          style={{ marginBottom: "1rem", borderRadius: 10 }}
          
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          size="small"
          name="password"
          value={form.password}
          onChange={onFormInputChange}hello
          style={{ marginBottom: "1rem" }}
        />

        

        <Button 
         
          onClick={onSubmit}
          sx={{ mb: 2, color: '#fff', border: 'none', borderRadius: 5, backgroundColor: '#077336 !important' }}>
          Login
        </Button>

        
        <div>
         
          <GoogleOneTap />
          </div>

      </form>
      </Box>
  );
}

export default Login;